import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import '../styles/WelcomePage.css';

function WelcomePage() {
  return (
    <div className="welcome-container">
      {/* Hero Section */}
      <motion.section 
        className="hero-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="hero-content">
          <motion.h1
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Find Your Pest Control Solution
          </motion.h1>
          <motion.p
            className="hero-subtitle"
            initial={{ y: 50 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            Quick, effective, and personalized solutions for your pest problems
          </motion.p>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.8 }}
          >
            <Link to="/pest-finder">
              <motion.button 
                className="hero-cta-button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Get Started
              </motion.button>
            </Link>
          </motion.div>
          
          {/* Trust Indicators */}
          <motion.div 
            className="trust-indicators"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8, duration: 0.8 }}
          >
            <div className="trust-item">
              <span className="trust-icon">✓</span>
              Free Service
            </div>
            <div className="trust-item">
              <span className="trust-icon">🛡️</span>
              Expert Advice
            </div>
            <div className="trust-item">
              <span className="trust-icon">⚡</span>
              No Sign-up Required
            </div>
          </motion.div>

          {/* Add after the trust indicators */}
          <motion.div 
            className="simple-steps"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.8 }}
          >
            <div className="step">
              <span className="step-dot"></span>
              <span className="step-text">Identify</span>
            </div>
            <div className="step-line"></div>
            <div className="step">
              <span className="step-dot"></span>
              <span className="step-text">Solution</span>
            </div>
            <div className="step-line"></div>
            <div className="step">
              <span className="step-dot"></span>
              <span className="step-text">Solve</span>
            </div>
          </motion.div>
        </div>
      </motion.section>

      {/* Statistics Section */}
      <section className="statistics-section">
        <div className="stats-container">
          <motion.div 
            className="stat-item"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            viewport={{ once: true }}
          >
            <div className="stat-icon">📊</div>
            <h3>
              <motion.span
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 2, delay: 0.5 }}
              >
                10,000+
              </motion.span>
            </h3>
            <p>Solutions Provided</p>
            <div className="stat-detail">Helping homeowners daily</div>
          </motion.div>

          <motion.div 
            className="stat-item"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="stat-icon">⭐</div>
            <h3>
              <motion.span
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 2, delay: 0.5 }}
              >
                98%
              </motion.span>
            </h3>
            <p>Success Rate</p>
            <div className="stat-detail">Verified by user feedback</div>
          </motion.div>

          <motion.div 
            className="stat-item"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            viewport={{ once: true }}
          >
            <div className="stat-icon">🕒</div>
            <h3>
              <motion.span
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 2, delay: 0.5 }}
              >
                24/7
              </motion.span>
            </h3>
            <p>Expert Support</p>
            <div className="stat-detail">Always here to help</div>
          </motion.div>
        </div>
        
        {/* Add decorative background elements */}
        <div className="stats-background">
          <div className="stats-shape shape-1"></div>
          <div className="stats-shape shape-2"></div>
          <div className="stats-shape shape-3"></div>
        </div>
      </section>

      {/* Features Section */}
      <section className="features-section">
        <div className="features-grid">
          <motion.div 
            className="feature-card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            whileHover={{ y: -5, boxShadow: '0 6px 20px rgba(0,0,0,0.1)' }}
          >
            <div className="feature-icon">📅</div>
            <h3>Seasonal Tips</h3>
            <p>Season-specific guidance for year-round pest control</p>
          </motion.div>

          <motion.div 
            className="feature-card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            whileHover={{ y: -5, boxShadow: '0 6px 20px rgba(0,0,0,0.1)' }}
          >
            <div className="feature-icon">💡</div>
            <h3>Expert Solutions</h3>
            <p>Get practical and effective solutions for pest problems</p>
          </motion.div>

          <motion.div 
            className="feature-card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.5 }}
            whileHover={{ y: -5, boxShadow: '0 6px 20px rgba(0,0,0,0.1)' }}
          >
            <div className="feature-icon">🛡️</div>
            <h3>Prevention Tips</h3>
            <p>Learn how to prevent future pest problems</p>
          </motion.div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials-section">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          What Our Users Say
        </motion.h2>
        <div className="testimonials-grid">
          <motion.div 
            className="testimonial-card"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.7 }}
            whileHover={{ scale: 1.02 }}
          >
            <p>"This tool helped me identify and solve my ant problem in minutes!"</p>
            <cite>- Sarah J.</cite>
          </motion.div>
          <motion.div 
            className="testimonial-card"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.8 }}
            whileHover={{ scale: 1.02 }}
          >
            <p>"Clear, practical advice that actually works. Highly recommended!"</p>
            <cite>- Michael R.</cite>
          </motion.div>
        </div>
      </section>

      {/* Bottom CTA Section */}
      <section className="bottom-cta-section">
        <motion.div 
          className="bottom-cta-content"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          <h2>Ready to Solve Your Pest Problem?</h2>
          <p>Get your personalized solution in minutes - it's free and easy to start.</p>
          <motion.div
            className="cta-buttons"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.6 }}
            viewport={{ once: true }}
          >
            <Link to="/pest-finder">
              <motion.button 
                className="primary-cta-button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Find My Solution
              </motion.button>
            </Link>
          </motion.div>
          <div className="bottom-cta-trust">
            <span>✓ Free Service</span>
            <span>•</span>
            <span>🛡️ Expert Advice</span>
            <span>•</span>
            <span>⚡ Immediate Access</span>
          </div>
        </motion.div>
      </section>
    </div>
  );
}

export default WelcomePage;