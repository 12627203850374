import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import WelcomePage from './pages/WelcomePage';
import PestFinder from './pages/PestFinder';
import './App.css';

function App() {
  useEffect(() => {
    // Force light mode at the document level
    document.documentElement.style.colorScheme = 'light';
    // Remove any dark mode classes that might be added automatically
    document.documentElement.classList.remove('dark-mode');
    document.body.classList.remove('dark-mode');
  }, []);

  return (
    <Router>
      <div className="app">
        <Header />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<WelcomePage />} />
            <Route path="/pest-finder" element={<PestFinder />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
