import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import '../styles/PestFinder.css';

function PestFinder() {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedPest, setSelectedPest] = useState(null);
  const [severity, setSeverity] = useState(null);

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.4
      }
    },
    exit: { 
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3
      }
    }
  };

  const categories = [
    { id: 'common-household', name: 'Common Household Pests' },
    { id: 'wood-destroying', name: 'Wood-Destroying Pests' },
    { id: 'biting-stinging', name: 'Biting & Stinging Pests' },
    { id: 'seasonal', name: 'Seasonal Invaders' },
    { id: 'rodents', name: 'Rodents' },
    { id: 'arachnids', name: 'Arachnids' }
  ];

  const pests = [
    { id: 'ants', name: 'Ants', category: 'common-household' },
    { id: 'cockroaches', name: 'Cockroaches', category: 'common-household' },
    { id: 'flies', name: 'Flies', category: 'common-household' },
    { id: 'silverfish', name: 'Silverfish', category: 'common-household' },
    { id: 'pantry-moths', name: 'Pantry Moths', category: 'common-household' },
    { id: 'carpet-beetles', name: 'Carpet Beetles', category: 'common-household' },

    // Wood-Destroying Pests
    { id: 'termites', name: 'Termites', category: 'wood-destroying' },
    { id: 'carpenter-bees', name: 'Carpenter Bees', category: 'wood-destroying' },
    { id: 'carpenter-ants', name: 'Carpenter Ants', category: 'wood-destroying' },

    // Biting & Stinging Pests
    { id: 'bed-bugs', name: 'Bed Bugs', category: 'biting-stinging' },
    { id: 'wasps', name: 'Wasps/Hornets', category: 'biting-stinging' },
    { id: 'mosquitoes', name: 'Mosquitoes', category: 'biting-stinging' },
    { id: 'fleas', name: 'Fleas', category: 'biting-stinging' },

    // Seasonal Invaders
    { id: 'stink-bugs', name: 'Stink Bugs', category: 'seasonal' },
    { id: 'boxelder-bugs', name: 'Boxelder Bugs', category: 'seasonal' },
    { id: 'ladybugs', name: 'Asian Lady Beetles', category: 'seasonal' },
    { id: 'earwigs', name: 'Earwigs', category: 'seasonal' },
    { id: 'crickets', name: 'Crickets', category: 'seasonal' },
    { id: 'centipedes', name: 'House Centipedes', category: 'seasonal' },
    { id: 'millipedes', name: 'Millipedes', category: 'seasonal' },

    // Rodents
    { id: 'mice', name: 'Mice', category: 'rodents' },
    { id: 'rats', name: 'Rats', category: 'rodents' },

    // Arachnids
    { id: 'spiders', name: 'Spiders', category: 'arachnids' },
    { id: 'scorpions', name: 'Scorpions', category: 'arachnids' },
    { id: 'mites', name: 'Mites', category: 'arachnids' }
  ];

  const severityLevels = [
    { id: 'low', name: 'Low - Just started noticing them' },
    { id: 'medium', name: 'Medium - Seeing them regularly' },
    { id: 'high', name: 'High - Serious infestation' }
  ];

  const solutions = {
    ants: {
      low: {
        immediate: "• Use a vinegar-water solution to clean ant trails\n" +
                  "• Place bay leaves or cinnamon near entry points\n" +
                  "• Seal small cracks and entry points\n" +
                  "• Keep food in airtight containers\n" +
                  "• Clean up spills and crumbs immediately",
        prevention: "• Store food in sealed containers\n" +
                   "• Clean kitchen surfaces daily\n" +
                   "• Fix leaky faucets and pipes\n" +
                   "• Seal entry points around windows and doors\n" +
                   "• Keep tree branches trimmed away from house\n" +
                   "• Remove standing water near foundation",
        seasonal: {
          spring: [
            "• Inspect and seal foundation cracks that appeared during winter",
            "• Clean up dead vegetation and mulch where ants nest",
            "• Check window seals and repair any winter damage",
            "• Remove rotting wood near the house",
            "• Start a regular perimeter cleaning routine"
          ],
          summer: [
            "• Keep plants and bushes trimmed away from house",
            "• Clean up food and drink spills outdoors immediately",
            "• Monitor ant activity around doors and windows",
            "• Keep outdoor eating areas clean",
            "• Check irrigation systems for leaks"
          ],
          fall: [
            "• Seal entry points before winter arrives",
            "• Remove fallen fruit and vegetation",
            "• Clean gutters to prevent moisture issues",
            "• Store firewood away from the house",
            "• Check weather stripping on doors and windows"
          ],
          winter: [
            "• Monitor indoor food storage areas",
            "• Check around heating vents and utilities",
            "• Keep indoor humidity levels balanced",
            "• Watch for ants seeking warmth indoors",
            "• Maintain regular cleaning schedule"
          ]
        }
      },
      medium: {
        immediate: "• Use ant baits containing borax or boric acid\n" +
                  "• Apply diatomaceous earth around entry points\n" +
                  "• Use a commercial ant spray barrier\n" +
                  "• Identify and eliminate food sources\n" +
                  "• Consider professional assessment",
        prevention: "• Install door sweeps on all exterior doors\n" +
                   "• Regular perimeter treatments\n" +
                   "• Remove all food sources including pet food\n" +
                   "• Fix any moisture issues in crawl spaces\n" +
                   "• Keep mulch away from foundation\n" +
                   "• Clean gutters regularly",
        seasonal: {
          spring: [
            "• Schedule professional perimeter treatment",
            "• Deep clean winter nesting areas",
            "• Repair any winter-damaged seals",
            "• Apply preventive barrier treatment",
            "• Check crawl spaces and attics"
          ],
          summer: [
            "• Increase monitoring of entry points",
            "• Maintain regular treatment schedule",
            "• Check outdoor water sources",
            "• Monitor pet feeding areas",
            "• Treat expanding ant colonies"
          ],
          fall: [
            "• Reinforce entry point seals",
            "• Clear vegetation from foundation",
            "• Apply preventive treatments",
            "• Clean and secure food storage",
            "• Prepare for winter ant movement"
          ],
          winter: [
            "• Check indoor warm spots regularly",
            "• Monitor utility entry points",
            "• Maintain indoor prevention methods",
            "• Watch for indoor nest formation",
            "• Keep food areas clean and secure"
          ]
        }
      },
      high: {
        immediate: "• Contact professional pest control immediately\n" +
                  "• Use combination of bait stations and barriers\n" +
                  "• Identify and seal all entry points\n" +
                  "• Address moisture issues\n" +
                  "• Schedule follow-up treatments",
        prevention: "• Schedule regular pest inspections\n" +
                   "• Complete structural repairs\n" +
                   "• Install professional grade barriers\n" +
                   "• Implement moisture control system\n" +
                   "• Regular monitoring of property\n" +
                   "• Professional sealing of entry points",
        seasonal: {
          spring: [
            "• Complete professional inspection",
            "• Implement comprehensive treatment plan",
            "• Address winter damage immediately",
            "• Begin long-term control measures",
            "• Set up professional monitoring system"
          ],
          summer: [
            "• Maintain intensive treatment schedule",
            "• Monitor treatment effectiveness",
            "• Adjust control strategies as needed",
            "• Regular professional inspections",
            "• Address outdoor nesting sites"
          ],
          fall: [
            "• Strengthen exclusion measures",
            "• Prepare indoor prevention plan",
            "• Final outdoor colony treatment",
            "• Secure all potential entry points",
            "• Set up winter monitoring system"
          ],
          winter: [
            "• Continue professional treatments",
            "• Monitor indoor activity closely",
            "• Maintain prevention systems",
            "• Regular perimeter checks",
            "• Address indoor nesting sites"
          ]
        }
      }
    },
    cockroaches: {
      low: {
        immediate: "• Use sticky traps to monitor activity\n" +
                  "• Apply food-grade diatomaceous earth\n" +
                  "• Seal small entry points\n" +
                  "• Keep food in airtight containers\n" +
                  "• Clean all surfaces thoroughly",
        prevention: "• Fix leaky pipes and faucets\n" +
                   "• Seal cracks around utilities\n" +
                   "• Remove cardboard boxes\n" +
                   "• Keep kitchen clean and dry\n" +
                   "• Reduce clutter in storage areas\n" +
                   "• Regular vacuum and cleaning schedule",
        seasonal: {
          spring: [
            "• Deep clean winter accumulation",
            "• Check for moisture issues",
            "• Inspect and seal entry points",
            "• Clean outdoor debris",
            "• Inspect stored items from winter"
          ],
          summer: [
            "• Monitor outdoor populations",
            "• Keep garbage areas clean",
            "• Check window and door seals",
            "• Maintain yard cleanliness",
            "• Regular kitchen inspections"
          ],
          fall: [
            "• Seal entry points before winter",
            "• Clean storage areas thoroughly",
            "• Remove outdoor harborage",
            "• Check weather stripping",
            "• Prepare monitoring system"
          ],
          winter: [
            "• Monitor indoor warm areas",
            "• Check plumbing for leaks",
            "• Maintain clean storage",
            "• Regular kitchen cleaning",
            "• Monitor utility areas"
          ]
        }
      },
      medium: {
        immediate: "• Use gel bait stations\n" +
                  "• Apply professional-grade insecticide\n" +
                  "• Implement comprehensive cleaning\n" +
                  "• Set up monitoring stations\n" +
                  "• Consider professional inspection",
        prevention: "• Professional exclusion work\n" +
                   "• Regular pest inspections\n" +
                   "• Improve ventilation\n" +
                   "• Install door sweeps\n" +
                   "• Seal all utility entries\n" +
                   "• Maintain cleaning protocol",
        seasonal: {
          spring: [
            "• Professional perimeter treatment",
            "• Repair winter damage",
            "• Deep clean all areas",
            "• Check moisture problems",
            "• Set up monitoring system"
          ],
          summer: [
            "• Increase treatment frequency",
            "• Monitor outdoor activity",
            "• Check drainage systems",
            "• Regular bait replacement",
            "• Inspect entry points"
          ],
          fall: [
            "• Strengthen exclusion measures",
            "• Clean potential habitats",
            "• Prepare indoor prevention",
            "• Check heating systems",
            "• Set up winter monitors"
          ],
          winter: [
            "• Monitor indoor activity",
            "• Check warm areas regularly",
            "• Maintain bait stations",
            "• Regular cleaning schedule",
            "• Inspect plumbing systems"
          ]
        }
      },
      high: {
        immediate: "• Contact pest control immediately\n" +
                  "• Implement emergency cleaning\n" +
                  "• Use professional baiting system\n" +
                  "• Set up extensive monitoring\n" +
                  "• Begin exclusion work",
        prevention: "• Monthly professional service\n" +
                   "• Complete exclusion system\n" +
                   "• Moisture control measures\n" +
                   "• Regular inspections\n" +
                   "• Staff/family training\n" +
                   "• Documentation system",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• Professional cleaning service",
            "• Comprehensive repairs",
            "• Update treatment plan",
            "• Staff/family training"
          ],
          summer: [
            "• Intensive treatment program",
            "• Regular monitoring checks",
            "• Adjust control methods",
            "• Maintain sanitation",
            "• Professional inspections"
          ],
          fall: [
            "• Complete exclusion work",
            "• Prepare winter strategy",
            "• Deep cleaning service",
            "• Update monitoring system",
            "• Professional assessment"
          ],
          winter: [
            "• Maintain treatment schedule",
            "• Monitor problem areas",
            "• Regular inspections",
            "• Update control methods",
            "• Professional cleaning"
          ]
        }
      }
    },
    flies: {
      low: {
        immediate: "• Install fly screens on windows and doors\n" +
                  "• Use fly paper or sticky traps\n" +
                  "• Clean garbage bins thoroughly\n" +
                  "• Remove standing water\n" +
                  "• Keep food covered and sealed",
        prevention: "• Ensure proper garbage disposal\n" +
                   "• Clean drains regularly\n" +
                   "• Fix window and door screens\n" +
                   "• Keep compost covered\n" +
                   "• Clean pet waste daily\n" +
                   "• Maintain yard cleanliness",
        seasonal: {
          spring: [
            "• Check and repair window screens",
            "• Clean winter debris from yard",
            "• Set up outdoor prevention",
            "• Clean drainage systems",
            "• Inspect door seals"
          ],
          summer: [
            "• Monitor garbage areas frequently",
            "• Keep doors closed when possible",
            "• Clean outdoor eating areas",
            "• Check window screens regularly",
            "• Maintain compost properly"
          ],
          fall: [
            "• Remove fallen fruit and vegetables",
            "• Clean gutters and drains",
            "• Secure compost bins",
            "• Check door sweeps",
            "• Remove yard debris"
          ],
          winter: [
            "• Monitor indoor plants",
            "• Check drain systems",
            "• Clean indoor garbage areas",
            "• Inspect utility entries",
            "• Maintain indoor cleanliness"
          ]
        }
      },
      medium: {
        immediate: "• Install professional fly traps\n" +
                  "• Use electric fly zappers\n" +
                  "• Deep clean affected areas\n" +
                  "• Identify and remove breeding sites\n" +
                  "• Consider professional inspection",
        prevention: "• Install air curtains\n" +
                   "• Regular drain cleaning service\n" +
                   "• Improve waste management\n" +
                   "• Upgrade door and window screens\n" +
                   "• Professional sanitation schedule\n" +
                   "• Monitor exterior lighting",
        seasonal: {
          spring: [
            "• Professional drain treatment",
            "• Install preventive measures",
            "• Clean winter accumulation",
            "• Check exterior entry points",
            "• Set up monitoring system"
          ],
          summer: [
            "• Increase cleaning frequency",
            "• Monitor trap effectiveness",
            "• Check exterior sanitation",
            "• Maintain prevention systems",
            "• Regular yard maintenance"
          ],
          fall: [
            "• Clean up seasonal debris",
            "• Prepare winter prevention",
            "• Service treatment systems",
            "• Check building integrity",
            "• Clean drainage systems"
          ],
          winter: [
            "• Monitor indoor breeding sites",
            "• Maintain drain treatments",
            "• Check warm areas regularly",
            "• Service indoor traps",
            "• Monitor utility areas"
          ]
        }
      },
      high: {
        immediate: "• Contact pest control service\n" +
                  "• Implement emergency sanitation\n" +
                  "• Install professional control systems\n" +
                  "• Remove all breeding sources\n" +
                  "• Begin exclusion measures",
        prevention: "• Professional treatment schedule\n" +
                   "• Complete waste management system\n" +
                   "• Regular professional cleaning\n" +
                   "• Advanced exclusion methods\n" +
                   "• Staff/resident training\n" +
                   "• Documentation protocol",
        seasonal: {
          spring: [
            "• Full facility treatment",
            "• Professional cleaning service",
            "• Update control systems",
            "• Staff training refresh",
            "• Complete area inspection"
          ],
          summer: [
            "• Intensive control program",
            "• Daily monitoring checks",
            "• Adjust treatment methods",
            "• Enhanced sanitation",
            "• Weekly professional service"
          ],
          fall: [
            "• System effectiveness review",
            "• Update winter strategy",
            "• Deep cleaning service",
            "• Seal entry points",
            "• Equipment maintenance"
          ],
          winter: [
            "• Maintain control systems",
            "• Regular site inspections",
            "• Monitor problem areas",
            "• Update treatment plans",
            "• Staff protocol review"
          ]
        }
      }
    },
    silverfish: {
      low: {
        immediate: "• Place sticky traps in corners and near pipes\n" +
                  "• Use food-grade diatomaceous earth in problem areas\n" +
                  "• Remove cardboard and paper from affected areas\n" +
                  "• Fix leaky pipes and reduce humidity\n" +
                  "• Vacuum thoroughly, especially in dark corners",
        prevention: "• Use dehumidifiers in damp areas\n" +
                   "• Store books and papers in sealed containers\n" +
                   "• Keep bathroom and basement areas dry\n" +
                   "• Seal cracks around pipes and utilities\n" +
                   "• Remove old newspapers and cardboard\n" +
                   "• Regular cleaning of dark, humid spaces",
        seasonal: {
          spring: [
            "• Check for winter moisture damage",
            "• Clean and organize storage areas",
            "• Inspect book collections",
            "• Check basement for leaks",
            "• Install moisture barriers"
          ],
          summer: [
            "• Monitor humidity levels",
            "• Check air conditioning units",
            "• Inspect bathroom seals",
            "• Clean storage areas",
            "• Check window condensation"
          ],
          fall: [
            "• Prepare storage areas for winter",
            "• Check weather stripping",
            "• Seal exterior cracks",
            "• Remove yard debris near house",
            "• Inspect pipe insulation"
          ],
          winter: [
            "• Monitor indoor humidity",
            "• Check heated areas",
            "• Inspect stored materials",
            "• Monitor basement conditions",
            "• Check for condensation issues"
          ]
        }
      },
      medium: {
        immediate: "• Apply targeted insecticide treatments\n" +
                  "• Set up comprehensive trap system\n" +
                  "• Remove infested materials\n" +
                  "• Install dehumidifiers\n" +
                  "• Begin exclusion measures",
        prevention: "• Professional moisture assessment\n" +
                   "• Install vapor barriers\n" +
                   "• Upgrade storage solutions\n" +
                   "• Regular professional inspections\n" +
                   "• Improve ventilation systems\n" +
                   "• Seal all potential entry points",
        seasonal: {
          spring: [
            "• Professional moisture control",
            "• Update ventilation systems",
            "• Deep clean storage areas",
            "• Check wall voids",
            "• Repair winter damage"
          ],
          summer: [
            "• Monitor treatment effectiveness",
            "• Check humidity control",
            "• Inspect dark spaces",
            "• Maintain dehumidifiers",
            "• Check pipe insulation"
          ],
          fall: [
            "• Prepare winter prevention",
            "• Seal new entry points",
            "• Check heating systems",
            "• Update moisture controls",
            "• Clean potential habitats"
          ],
          winter: [
            "• Monitor problem areas",
            "• Check heating efficiency",
            "• Inspect storage spaces",
            "• Maintain humidity control",
            "• Regular trap checks"
          ]
        }
      },
      high: {
        immediate: "• Contact pest control professional\n" +
                  "• Remove severely infested materials\n" +
                  "• Install professional dehumidification\n" +
                  "• Begin intensive treatment plan\n" +
                  "• Implement moisture reduction",
        prevention: "• Monthly professional service\n" +
                   "• Complete moisture management system\n" +
                   "• Professional exclusion work\n" +
                   "• Regular monitoring program\n" +
                   "• Update storage systems\n" +
                   "• Staff/family education",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• Professional cleaning service",
            "• Moisture system upgrade",
            "• Complete area inspection",
            "• Update control methods"
          ],
          summer: [
            "• Intensive humidity control",
            "• Regular service visits",
            "• Monitor dark spaces",
            "• Equipment maintenance",
            "• Update treatment plan"
          ],
          fall: [
            "• Pre-winter preparation",
            "• Deep cleaning service",
            "• System effectiveness check",
            "• Update control methods",
            "• Complete exclusion work"
          ],
          winter: [
            "• Maintain treatment schedule",
            "• Monitor problem areas",
            "• Check control systems",
            "• Regular inspections",
            "• Update prevention plan"
          ]
        }
      }
    },
    'pantry-moths': {
      low: {
        immediate: "• Inspect and discard infested food items\n" +
                  "• Clean all shelves and crevices thoroughly\n" +
                  "• Install pheromone traps\n" +
                  "• Transfer dry goods to sealed containers\n" +
                  "• Vacuum all cabinet corners and edges",
        prevention: "• Store food in airtight glass or plastic containers\n" +
                   "• Inspect groceries before storing\n" +
                   "• Clean spills immediately\n" +
                   "• Regular pantry inventory checks\n" +
                   "• Keep dry goods in cool, dry places\n" +
                   "• Use bay leaves in storage containers",
        seasonal: {
          spring: [
            "• Deep clean pantry storage",
            "• Check stored winter goods",
            "• Inspect new groceries thoroughly",
            "• Clean cabinet corners",
            "• Replace old containers"
          ],
          summer: [
            "• Monitor pheromone traps",
            "• Check food storage temps",
            "• Inspect incoming produce",
            "• Regular pantry cleaning",
            "• Check sealed containers"
          ],
          fall: [
            "• Inspect holiday baking goods",
            "• Clean storage containers",
            "• Check pantry seals",
            "• Organize stored items",
            "• Monitor dark corners"
          ],
          winter: [
            "• Rotate stored goods",
            "• Check holiday storage",
            "• Monitor warm areas",
            "• Inspect sealed items",
            "• Clean storage areas"
          ]
        }
      },
      medium: {
        immediate: "• Remove all food from affected areas\n" +
                  "• Deep clean with appropriate cleaners\n" +
                  "• Set multiple pheromone traps\n" +
                  "• Dispose of all potentially infested items\n" +
                  "• Begin systematic cabinet treatment",
        prevention: "• Install early detection systems\n" +
                   "• Implement rotation system for stored goods\n" +
                   "• Regular professional inspections\n" +
                   "• Use preventive storage methods\n" +
                   "• Monitor temperature and humidity\n" +
                   "• Establish cleaning schedule",
        seasonal: {
          spring: [
            "• Professional pantry treatment",
            "• Update storage systems",
            "• Deep clean all areas",
            "• Check wall voids",
            "• Install monitoring systems"
          ],
          summer: [
            "• Increase trap monitoring",
            "• Check ventilation",
            " Regular inspections",
            "• Maintain prevention systems",
            "• Update storage methods"
          ],
          fall: [
            "• Pre-holiday inspection",
            "• Clean storage areas",
            "• Check entry points",
            "• Update prevention methods",
            "• Monitor problem areas"
          ],
          winter: [
            "• Monitor stored goods",
            "• Check heating areas",
            "• Regular trap checks",
            "• Inspect storage seals",
            "• Maintain cleaning schedule"
          ]
        }
      },
      high: {
        immediate: "• Contact pest control professional\n" +
                  "• Complete removal of stored goods\n" +
                  "• Install professional monitoring system\n" +
                  "• Begin intensive treatment plan\n" +
                  "• Seal and treat all cracks",
        prevention: "• Monthly professional monitoring\n" +
                   "• Complete storage system upgrade\n" +
                   "• Professional exclusion work\n" +
                   "• Regular inspection schedule\n" +
                   "• Temperature monitoring system\n" +
                   "• Staff/family training",
        seasonal: {
          spring: [
            "• Full facility treatment",
            "• Professional cleaning",
            "• System upgrades",
            "• Staff training",
            "• Complete inspection"
          ],
          summer: [
            "• Intensive monitoring",
            "• Regular service visits",
            "• Update treatment plan",
            "• Check control systems",
            "• Monitor all areas"
          ],
          fall: [
            "• Pre-holiday preparation",
            "• Deep cleaning service",
            "• System evaluation",
            "• Update procedures",
            "• Complete treatment"
          ],
          winter: [
            "• Maintain treatment plan",
            "• Regular inspections",
            "• Monitor storage areas",
            "• Update prevention",
            "• System maintenance"
          ]
        }
      }
    },
    'carpet-beetles': {
      low: {
        immediate: "• Vacuum thoroughly all carpets and fabrics\n" +
                  "• Wash affected fabrics in hot water\n" +
                  "• Clean baseboards and corners carefully\n" +
                  "• Inspect window sills and light fixtures\n" +
                  "• Remove any visible larvae or beetles",
        prevention: "• Regular vacuuming of carpets and upholstery\n" +
                   "• Store wool and silk items in sealed containers\n" +
                   "• Use cedar blocks in closets\n" +
                   "• Keep humidity levels low\n" +
                   "• Clean lint from vents and registers\n" +
                   "• Regular inspection of stored fabrics",
        seasonal: {
          spring: [
            "• Deep clean all carpets",
            "• Inspect winter storage items",
            "• Clean air vents thoroughly",
            "• Check window treatments",
            "• Inspect stored winter clothes"
          ],
          summer: [
            "• Monitor light fixtures",
            "• Check stored winter items",
            "• Inspect window sills",
            "• Clean upholstered furniture",
            "• Check closet corners"
          ],
          fall: [
            "• Prepare winter storage",
            "• Clean summer fabrics",
            "• Inspect heating vents",
            "• Check door sweeps",
            "• Clean baseboards thoroughly"
          ],
          winter: [
            "• Monitor stored items",
            "• Check warm areas",
            "• Inspect holiday decorations",
            "• Clean storage areas",
            "• Check heating vents"
          ]
        }
      },
      medium: {
        immediate: "• Apply appropriate insecticide treatment\n" +
                  "• Deep clean all fabric surfaces\n" +
                  "• Remove and treat affected items\n" +
                  "• Set up monitoring traps\n" +
                  "• Begin systematic room treatment",
        prevention: "• Professional carpet treatments\n" +
                   "• Install door sweeps\n" +
                   "• Regular professional inspections\n" +
                   "• Upgrade storage solutions\n" +
                   "• Improve ventilation\n" +
                   "• Use preventive sprays",
        seasonal: {
          spring: [
            "• Professional carpet cleaning",
            "• Update storage methods",
            "• Deep clean all areas",
            "• Check entry points",
            "• Install monitoring system"
          ],
          summer: [
            "• Monitor trap effectiveness",
            "• Check ventilation systems",
            "• Regular inspections",
            "• Maintain prevention methods",
            "• Update treatment plan"
          ],
          fall: [
            "• Pre-winter inspection",
            "• Clean storage areas",
            "• Check entry points",
            "• Update prevention",
            "• Monitor problem areas"
          ],
          winter: [
            "• Monitor stored textiles",
            "• Check heating areas",
            "• Regular trap checks",
            "• Inspect dark corners",
            "• Maintain treatment plan"
          ]
        }
      },
      high: {
        immediate: "• Contact pest control professional\n" +
                  "• Remove severely infested items\n" +
                  "• Install professional monitoring system\n" +
                  "• Begin intensive treatment plan\n" +
                  "• Seal and treat all entry points",
        prevention: "• Monthly professional service\n" +
                   "• Complete storage system upgrade\n" +
                   "• Professional exclusion work\n" +
                   "• Regular monitoring program\n" +
                   "• Climate control system\n" +
                   "• Staff/family education",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• Professional cleaning",
            "• System upgrades",
            "• Complete inspection",
            "• Update control methods"
          ],
          summer: [
            "• Intensive monitoring",
            "• Regular service visits",
            "• Update treatment plan",
            "• Check control systems",
            "• Monitor all areas"
          ],
          fall: [
            "• Pre-winter preparation",
            "• Deep cleaning service",
            "• System evaluation",
            "• Update procedures",
            "• Complete treatment"
          ],
          winter: [
            "• Maintain treatment schedule",
            "• Monitor problem areas",
            "• Check control systems",
            "• Regular inspections",
            "• Update prevention plan"
          ]
        }
      }
    },
    'termites': {
      low: {
        immediate: "• Document all signs of activity\n" +
                  "• Remove wood debris from property\n" +
                  "• Fix moisture issues and leaks\n" +
                  "• Reduce wood-to-ground contact\n" +
                  "• Schedule professional inspection",
        prevention: "• Maintain proper drainage around foundation\n" +
                   "• Keep mulch away from foundation\n" +
                   "• Fix leaky pipes and gutters\n" +
                   "• Regular foundation inspections\n" +
                   "• Remove dead trees and stumps\n" +
                   "• Store firewood away from house",
        seasonal: {
          spring: [
            "• Inspect for swarmers",
            "• Check foundation thoroughly",
            "• Clean gutters and downspouts",
            "• Inspect wooden structures",
            "• Check crawl space ventilation"
          ],
          summer: [
            "• Monitor moisture levels",
            "• Check irrigation patterns",
            "• Inspect deck and fence posts",
            "• Monitor mulch areas",
            "• Check wood siding"
          ],
          fall: [
            "• Remove fallen wood debris",
            "• Clean gutters before winter",
            "• Check roof for leaks",
            "• Inspect attic spaces",
            "• Remove dead vegetation"
          ],
          winter: [
            "• Monitor indoor humidity",
            "• Check basement for moisture",
            "• Inspect utility entries",
            "• Monitor heating system areas",
            "• Check stored wood items"
          ]
        }
      },
      medium: {
        immediate: "• Contact pest control immediately\n" +
                  "• Document extent of damage\n" +
                  "• Begin moisture remediation\n" +
                  "• Remove affected wood if possible\n" +
                  "• Set up monitoring stations",
        prevention: "• Professional monitoring system\n" +
                   "• Regular termite inspections\n" +
                   "• Improve drainage systems\n" +
                   "• Install moisture barriers\n" +
                   "• Professional wood treatment\n" +
                   "• Update building materials",
        seasonal: {
          spring: [
            "• Professional treatment plan",
            "• Monitor swarm activity",
            "• Update bait stations",
            "• Check treatment barriers",
            "• Inspect wood structures"
          ],
          summer: [
            "• Monitor soil treatment",
            "• Check moisture controls",
            "• Regular inspections",
            "• Update prevention methods",
            "• Check bait stations"
          ],
          fall: [
            "• Pre-winter inspection",
            "• Update treatments",
            "• Check structure integrity",
            "• Monitor problem areas",
            "• Prepare monitoring plan"
          ],
          winter: [
            "• Check indoor areas",
            "• Monitor bait stations",
            "• Inspect foundation",
            "• Check moisture levels",
            "• Update treatment plan"
          ]
        }
      },
      high: {
        immediate: "• Emergency pest control service\n" +
                  "• Begin structural assessment\n" +
                  "• Install comprehensive bait system\n" +
                  "• Start chemical barrier treatment\n" +
                  "• Document all damage",
        prevention: "• Monthly professional monitoring\n" +
                   "• Complete moisture management\n" +
                   " Structural modifications\n" +
                   "• Regular treatment schedule\n" +
                   "• Professional wood treatment\n" +
                   "• Building material upgrades",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• Structural repairs",
            "• System upgrades",
            "• Complete inspection",
            "• Update treatment plan"
          ],
          summer: [
            "• Intensive monitoring",
            "• Regular treatments",
            "• Check all systems",
            "• Monitor moisture",
            "• Update prevention"
          ],
          fall: [
            "• Pre-winter preparation",
            "• System evaluation",
            "• Update treatments",
            "• Complete repairs",
            "• Prevention upgrades"
          ],
          winter: [
            "• Monitor all systems",
            "• Check problem areas",
            "• Update treatments",
            "• Regular inspections",
            "• Maintain prevention"
          ]
        }
      }
    },
    'carpenter-bees': {
      low: {
        immediate: "• Inspect wood surfaces for round holes\n" +
                  "• Apply residual insecticide to entry holes\n" +
                  "• Fill existing holes with wood putty\n" +
                  "• Paint or seal exposed wood\n" +
                  "• Monitor for new activity",
        prevention: "• Paint or stain exposed wood surfaces\n" +
                   "• Regular inspection of wood structures\n" +
                   "• Use treated lumber for repairs\n" +
                   "• Install bee-proof materials\n" +
                   "• Maintain painted surfaces\n" +
                   "• Check eaves and fascia boards",
        seasonal: {
          spring: [
            "• Check for new bee activity",
            "• Inspect winter damage",
            "• Treat visible holes",
            "• Paint exposed wood",
            "• Monitor wood structures"
          ],
          summer: [
            "• Monitor bee activity",
            "• Check treatment effectiveness",
            "• Inspect wood surfaces",
            "• Fill treated holes",
            "• Check preventive measures"
          ],
          fall: [
            "• Final inspection before winter",
            "• Seal remaining holes",
            "• Touch up wood treatments",
            "• Check structural integrity",
            "• Repair damaged wood"
          ],
          winter: [
            "• Inspect stored lumber",
            "• Check exterior wood",
            "• Plan spring treatments",
            "• Document problem areas",
            "• Prepare prevention plan"
          ]
        }
      },
      medium: {
        immediate: "• Contact pest professional\n" +
                  "• Treat all active holes\n" +
                  "• Begin wood replacement process\n" +
                  "• Install preventive measures\n" +
                  "• Document extent of damage",
        prevention: "• Professional wood treatments\n" +
                   "• Regular structural inspections\n" +
                   "• Upgrade building materials\n" +
                   "• Install protective barriers\n" +
                   "• Implement monitoring system\n" +
                   "• Schedule regular maintenance",
        seasonal: {
          spring: [
            "• Professional treatment plan",
            "• Monitor bee emergence",
            "• Apply preventive sprays",
            "• Check previous damage",
            "• Install deterrents"
          ],
          summer: [
            "• Regular inspections",
            "• Update treatments",
            "• Monitor activity levels",
            "• Check wood integrity",
            "• Maintain prevention"
          ],
          fall: [
            "• Pre-winter preparations",
            "• Complete repairs",
            "• Final treatments",
            "• Seal entry points",
            "• Document problem areas"
          ],
          winter: [
            "• Assess structural damage",
            "• Plan spring treatment",
            "• Check sealed areas",
            "• Prepare materials",
            "• Update prevention plan"
          ]
        }
      },
      high: {
        immediate: "• Emergency pest service\n" +
                  "• Begin wood replacement\n" +
                  "• Install comprehensive barriers\n" +
                  "• Start intensive treatment\n" +
                  "• Structural assessment",
        prevention: "• Monthly professional monitoring\n" +
                   "• Complete wood protection system\n" +
                   "• Structural modifications\n" +
                   "• Regular treatment schedule\n" +
                   "• Professional maintenance plan\n" +
                   "• Material upgrades",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• Structural repairs",
            "• System installation",
            "• Complete inspection",
            "• Prevention setup"
          ],
          summer: [
            "• Intensive monitoring",
            "• Regular treatments",
            "• System checks",
            "• Update prevention",
            "• Damage assessment"
          ],
          fall: [
            "• Pre-winter preparation",
            "• System evaluation",
            "• Complete repairs",
            "• Update treatments",
            "• Prevention review"
          ],
          winter: [
            "• Monitor structures",
            "• Check repairs",
            "• Plan spring action",
            "• Update systems",
            "• Maintain prevention"
          ]
        }
      }
    },
    'carpenter-ants': {
      low: {
        immediate: "• Locate and mark ant trails\n" +
                  "• Remove food and water sources\n" +
                  "• Apply boric acid bait stations\n" +
                  "• Seal visible entry points\n" +
                  "• Check for moisture issues",
        prevention: "• Fix moisture problems and leaks\n" +
                 "• Trim trees away from house\n" +
                 "• Remove dead wood and stumps\n" +
                 "• Keep firewood away from structure\n" +
                 "• Seal gaps around utilities\n" +
                 "• Regular wood inspections",
        seasonal: {
          spring: [
            "• Check for swarmers",
            "• Inspect roof lines",
            "• Check window frames",
            "• Monitor tree branches",
            "• Inspect foundation contact"
          ],
          summer: [
            "• Monitor outdoor activity",
            "• Check deck and porch areas",
            "• Inspect mulch areas",
            "• Monitor moisture levels",
            "• Check wood structures"
          ],
          fall: [
            "• Remove yard debris",
            "• Check gutters and roof",
            "• Seal winter entry points",
            "• Inspect attic spaces",
            "• Remove dead vegetation"
          ],
          winter: [
            "• Monitor indoor activity",
            "• Check utility areas",
            "• Inspect wood storage",
            "• Monitor moisture levels",
            "• Check wall voids"
          ]
        }
      },
      medium: {
        immediate: "• Contact pest professional\n" +
                  "• Set up comprehensive bait system\n" +
                  "• Begin moisture remediation\n" +
                  "• Remove damaged wood\n" +
                  "• Document infestation areas",
        prevention: "• Professional wood treatments\n" +
                 "• Regular pest inspections\n" +
                 "• Improve drainage systems\n" +
                 "• Install moisture barriers\n" +
                 "• Implement monitoring system\n" +
                 "• Schedule maintenance checks",
        seasonal: {
          spring: [
            "• Professional treatment plan",
            "• Monitor swarm season",
            "• Update bait stations",
            "• Check moisture controls",
            "• Inspect wood areas"
          ],
          summer: [
            "• Regular inspections",
            "• Monitor bait stations",
            "• Check treatment areas",
            "• Update prevention methods",
            "• Monitor wood condition"
          ],
          fall: [
            "• Pre-winter inspection",
            "• Update treatments",
            "• Check structure integrity",
            "• Monitor problem areas",
            "• Prepare winter plan"
          ],
          winter: [
            "• Check indoor areas",
            "• Monitor bait systems",
            "• Inspect foundation",
            "• Check moisture levels",
            "• Update treatment plan"
          ]
        }
      },
      high: {
        immediate: "• Emergency pest service\n" +
                  "• Begin structural assessment\n" +
                  "• Install professional bait system\n" +
                  "• Start intensive treatment\n" +
                  "• Document damage extent",
        prevention: "• Monthly professional monitoring\n" +
                 "• Complete moisture management\n" +
                 "• Structural modifications\n" +
                 "• Regular treatment schedule\n" +
                 "• Professional wood treatment\n" +
                 "• Building material upgrades",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• Structural repairs",
            "• System upgrades",
            " Complete inspection",
            "• Update treatment plan"
          ],
          summer: [
            "• Intensive monitoring",
            "• Regular treatments",
            "• Check all systems",
            "• Monitor moisture",
            "• Update prevention"
          ],
          fall: [
            "• Pre-winter preparation",
            "• System evaluation",
            "• Update treatments",
            "• Complete repairs",
            "• Prevention upgrades"
          ],
          winter: [
            "• Monitor all systems",
            "• Check problem areas",
            "• Update treatments",
            "• Regular inspections",
            "• Maintain prevention"
          ]
        }
      }
    },
    'bed-bugs': {
      low: {
        immediate: "• Inspect and isolate affected bedding\n" +
                  "• Wash all fabrics in hot water (130°F)\n" +
                  "• Vacuum thoroughly and dispose of bag\n" +
                  "• Use mattress and box spring encasements\n" +
                  "• Set up monitoring devices",
        prevention: "• Regular inspection of bedding\n" +
                 "• Use light-colored bedding for detection\n" +
                 "• Keep beds away from walls\n" +
                 "• Install bed bug interceptors\n" +
                 "• Reduce clutter around beds\n" +
                 "• Inspect used furniture before bringing inside",
        seasonal: {
          spring: [
            "• Deep clean all bedding",
            "• Check winter storage items",
            "• Inspect bed frames thoroughly",
            "• Clean and check headboards",
            "• Monitor interceptor traps"
          ],
          summer: [
            "• Check travel items carefully",
            "• Inspect window AC units",
            "• Monitor sleeping areas",
            "• Check furniture joints",
            "• Inspect guest rooms"
          ],
          fall: [
            "• Inspect stored summer items",
            "• Check heating units",
            "• Monitor bed areas",
            "• Inspect closet areas",
            "• Check furniture seams"
          ],
          winter: [
            "• Monitor heated areas",
            "• Check holiday guests' areas",
            "• Inspect storage spaces",
            "• Monitor sleeping areas",
            "• Check baseboards"
          ]
        }
      },
      medium: {
        immediate: "• Contact pest professional\n" +
                  "• Begin isolation protocols\n" +
                  "• Heat treat possible items\n" +
                  "• Set up comprehensive monitoring\n" +
                  "• Document spread patterns",
        prevention: "• Professional monitoring system\n" +
                 "• Regular inspections schedule\n" +
                 "• Update furniture protection\n" +
                 "• Install advanced interceptors\n" +
                 "• Implement reporting system\n" +
                 "• Staff/family training",
        seasonal: {
          spring: [
            "• Professional treatment plan",
            "• Deep cleaning service",
            "• Update monitoring",
            "• Check spread patterns",
            "• Install new barriers"
          ],
          summer: [
            "• Regular inspections",
            "• Monitor high-risk areas",
            "• Check treatment success",
            "• Update prevention methods",
            "• Maintain barriers"
          ],
          fall: [
            "• Pre-winter treatment",
            "• Complete exclusion",
            "• Monitor gathering",
            "• Update barriers",
            "• Prepare winter plan"
          ],
          winter: [
            "• Check heated areas",
            "• Monitor treatments",
            "• Inspect furniture",
            "• Update methods",
            "• Maintain prevention"
          ]
        }
      },
      high: {
        immediate: "• Emergency pest service\n" +
                  "• Begin heat treatments\n" +
                  "• Install monitoring systems\n" +
                  "• Start intensive treatment\n" +
                  "• Document infestation extent",
        prevention: "• Weekly professional monitoring\n" +
                 "• Complete protection system\n" +
                 "• Regular heat treatments\n" +
                 "• Advanced monitoring setup\n" +
                 "• Professional inspection schedule\n" +
                 "• Comprehensive staff training",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• Professional cleaning",
            "• System upgrades",
            "• Complete inspection",
            "• Update protocols"
          ],
          summer: [
            "• Intensive monitoring",
            "• Weekly treatments",
            "• System checks",
            "• Update prevention",
            "• Staff training"
          ],
          fall: [
            "• Pre-winter preparation",
            "• System evaluation",
            "• Complete treatments",
            "• Update methods",
            "• Prevention review"
          ],
          winter: [
            "• Monitor all areas",
            "• Regular treatments",
            "• Check systems",
            "• Update prevention",
            "• Maintain protocols"
          ]
        }
      }
    },
    'wasps': {
      low: {
        immediate: "• Identify nest locations from safe distance\n" +
                  "• Apply wasp spray at night if accessible\n" +
                  "• Remove empty/abandoned nests\n" +
                  "• Seal entry points to structure\n" +
                  "• Set up wasp traps away from house",
        prevention: "• Regular inspection of eaves and overhangs\n" +
                 "• Seal holes in building exterior\n" +
                 "• Keep food covered outdoors\n" +
                 "• Maintain screens on windows/vents\n" +
                 "• Remove food sources\n" +
                 "• Keep garbage containers sealed",
        seasonal: {
          spring: [
            "• Check for queen activity",
            "• Inspect previous nest sites",
            "• Seal winter entry points",
            "• Set up early traps",
            "• Monitor building exterior"
          ],
          summer: [
            "• Monitor nest development",
            "• Check trap effectiveness",
            "• Inspect outdoor areas",
            "• Watch for increased activity",
            "• Check entry points"
          ],
          fall: [
            "• Remove abandoned nests",
            "• Seal entry points",
            "• Check roof areas",
            "• Monitor dying colonies",
            "• Prepare winter prevention"
          ],
          winter: [
            "• Inspect attic spaces",
            "• Check wall voids",
            "• Seal interior gaps",
            "• Plan spring prevention",
            "• Document problem areas"
          ]
        }
      },
      medium: {
        immediate: "• Contact pest professional\n" +
                  "• Establish safety perimeter\n" +
                  "• Begin systematic treatment\n" +
                  "• Install preventive measures\n" +
                  "• Document nest locations",
        prevention: "• Professional exclusion work\n" +
                 "• Regular structure inspections\n" +
                 "• Install preventive treatments\n" +
                 "• Modify attractive conditions\n" +
                 "• Implement monitoring system\n" +
                 "• Schedule maintenance checks",
        seasonal: {
          spring: [
            "• Professional prevention plan",
            "• Monitor queen activity",
            "• Apply preventive sprays",
            "• Check previous sites",
            "• Install deterrents"
          ],
          summer: [
            "• Regular inspections",
            "• Update treatments",
            "• Monitor nest sites",
            "• Check preventive measures",
            "• Maintain exclusion"
          ],
          fall: [
            "• Remove active nests",
            "• Complete repairs",
            "• Final treatments",
            "• Seal entry points",
            "• Document activity areas"
          ],
          winter: [
            "• Inspect structure",
            "• Plan spring treatment",
            "• Check sealed areas",
            "• Prepare materials",
            "• Update prevention plan"
          ]
        }
      },
      high: {
        immediate: "• Emergency pest service\n" +
                  "• Implement safety protocols\n" +
                  "• Begin intensive treatment\n" +
                  "• Install comprehensive barriers\n" +
                  "• Professional nest removal",
        prevention: "• Monthly professional monitoring\n" +
                 "• Complete exclusion system\n" +
                 "• Regular treatment schedule\n" +
                 "• Professional maintenance plan\n" +
                 "• Area modification plan\n" +
                 "• Staff/family safety training",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• Structural exclusion",
            "• System installation",
            "• Complete inspection",
            "• Prevention setup"
          ],
          summer: [
            "• Regular treatments",
            "• System monitoring",
            "• Check barriers",
            "• Tree management",
            "• Activity assessment"
          ],
          fall: [
            "• Intensive treatment",
            "• System evaluation",
            "• Complete exclusion",
            "• Update methods",
            "• Prevention review"
          ],
          winter: [
            "• Monitor systems",
            "• Maintain treatments",
            "• Check exclusion",
            "• Update prevention",
            "• Review protocols"
          ]
        }
      }
    },
    'mosquitoes': {
      low: {
        immediate: "• Remove standing water sources\n" +
                  "• Clear clogged gutters\n" +
                  "• Install or repair screens\n" +
                  "• Use EPA-approved repellents\n" +
                  "• Trim overgrown vegetation",
        prevention: "• Maintain proper drainage\n" +
                 "• Keep grass and weeds short\n" +
                 "• Clean gutters regularly\n" +
                 "• Fix leaky outdoor faucets\n" +
                 "• Empty water containers weekly\n" +
                 "• Install or maintain screens",
        seasonal: {
          spring: [
            "• Remove winter debris",
            "• Check drainage systems",
            "• Repair window screens",
            "• Clear standing water",
            "• Start yard maintenance"
          ],
          summer: [
            "• Monitor water sources",
            "• Maintain yard drainage",
            "• Check screen integrity",
            "• Treat breeding sites",
            "• Regular yard upkeep"
          ],
          fall: [
            "• Clean gutters",
            "• Remove fallen leaves",
            "• Check drainage",
            "• Store containers upside down",
            "• Final yard cleanup"
          ],
          winter: [
            "• Monitor indoor areas",
            "• Check stored items",
            "• Plan spring prevention",
            "• Maintain drainage",
            "• Document problem areas"
          ]
        }
      },
      medium: {
        immediate: "• Contact pest professional\n" +
                  "• Begin yard treatment program\n" +
                  "• Install mosquito traps\n" +
                  "• Implement drainage solutions\n" +
                  "• Start barrier treatments",
        prevention: "• Professional yard treatments\n" +
                 "• Regular property inspection\n" +
                 "• Install deterrent systems\n" +
                 "• Improve drainage systems\n" +
                 "• Monitor breeding sites\n" +
                 "• Maintain treatment schedule",
        seasonal: {
          spring: [
            "• Professional treatment plan",
            "• Install prevention systems",
            "• Check breeding sites",
            "• Update drainage",
            "• Start monitoring program"
          ],
          summer: [
            "• Regular treatments",
            "• Monitor effectiveness",
            "• Check water sources",
            "• Maintain prevention",
            "• Update problem areas"
          ],
          fall: [
            "• Final treatments",
            "• Prevention maintenance",
            "• Clear problem areas",
            "• Update drainage",
            "• Prepare winter plan"
          ],
          winter: [
            "• Check indoor areas",
            "• Monitor systems",
            "• Plan spring treatment",
            "• Maintain prevention",
            "• Update strategies"
          ]
        }
      },
      high: {
        immediate: "• Emergency pest service\n" +
                  "• Install professional systems\n" +
                  "• Begin intensive treatment\n" +
                  "• Implement drainage solutions\n" +
                  "• Start comprehensive control",
        prevention: "• Weekly professional service\n" +
                 "• Complete property management\n" +
                 "• Advanced monitoring system\n" +
                 "• Professional drainage solutions\n" +
                 "• Regular treatment schedule\n" +
                 "• Staff/family education",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• System installation",
            "• Complete inspection",
            "• Update drainage",
            "• Prevention setup"
          ],
          summer: [
            "• Weekly treatments",
            "• System monitoring",
            "• Regular inspection",
            "• Update prevention",
            "• Control assessment"
          ],
          fall: [
            "• Final treatments",
            "• System evaluation",
            "• Complete maintenance",
            "• Update methods",
            "• Prevention review"
          ],
          winter: [
            "• Monitor systems",
            "• Maintain treatments",
            "• Check exclusion",
            "• Update prevention",
            "• Review protocols"
          ]
        }
      }
    },
    'fleas': {
      low: {
        immediate: "• Vacuum thoroughly and frequently\n" +
                  "• Wash all pet bedding in hot water\n" +
                  "• Treat pets with vet-approved products\n" +
                  "• Use flea combs on pets daily\n" +
                  "• Clean or dispose of vacuum bags",
        prevention: "• Regular pet flea treatments\n" +
                   "• Vacuum high-traffic areas daily\n" +
                   "• Keep grass cut short\n" +
                   "• Regular pet bedding washing\n" +
                   "• Monitor pets for scratching\n" +
                   "• Keep wildlife away from yard",
        seasonal: {
          spring: [
            "• Begin pet treatments",
            "• Check yard for activity",
            "• Clean winter pet areas",
            "• Inspect basement areas",
            "• Start prevention program"
          ],
          summer: [
            "• Monitor pet activity",
            "• Check outdoor areas",
            "• Regular yard maintenance",
            "• Inspect pet bedding",
            "• Continue treatments"
          ],
          fall: [
            "• Check indoor pet areas",
            "• Treat yard before winter",
            "• Clean pet spaces",
            "• Monitor activity",
            "• Update prevention"
          ],
          winter: [
            "• Monitor indoor areas",
            "• Check pet bedding",
            "• Maintain cleaning schedule",
            "• Inspect warm spots",
            "• Continue pet treatment"
          ]
        }
      },
      medium: {
        immediate: "• Contact pest professional\n" +
                  "• Begin indoor treatment program\n" +
                  "• Treat yard perimeter\n" +
                  "• Isolate infested areas\n" +
                  "• Coordinate with veterinarian",
        prevention: "• Professional treatment schedule\n" +
                   "• Regular property inspection\n" +
                   "• Comprehensive pet treatment\n" +
                   "• Yard management program\n" +
                   "• Monitor problem areas\n" +
                   "• Update cleaning protocols",
        seasonal: {
          spring: [
            "• Professional treatment plan",
            "• Update pet treatments",
            "• Check yard areas",
            "• Begin monitoring",
            "• Install prevention"
          ],
          summer: [
            "• Regular treatments",
            "• Monitor effectiveness",
            "• Check all areas",
            "• Update pet protection",
            "• Maintain prevention"
          ],
          fall: [
            "• Pre-winter treatment",
            "• Update prevention",
            "• Final yard treatment",
            "• Check indoor areas",
            "• Prepare winter plan"
          ],
          winter: [
            "• Monitor indoor spaces",
            "• Continue treatment",
            "• Check pet areas",
            "• Update methods",
            "• Maintain prevention"
          ]
        }
      },
      high: {
        immediate: "• Emergency pest service\n" +
                  "• Begin intensive treatment\n" +
                  "• Professional yard treatment\n" +
                  "• Coordinate pet treatments\n" +
                  "• Start comprehensive control",
        prevention: "• Weekly professional service\n" +
                   "• Complete property management\n" +
                   "• Advanced monitoring system\n" +
                   "• Professional pet treatments\n" +
                   "• Regular inspection schedule\n" +
                   "• Family/pet safety protocols",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• System installation",
            "• Complete inspection",
            "• Pet treatment plan",
            "• Prevention setup"
          ],
          summer: [
            "• Weekly treatments",
            "• Regular monitoring",
            "• Area inspection",
            "• Update prevention",
            "• Control assessment"
          ],
          fall: [
            "• Final treatments",
            "• System evaluation",
            "• Complete maintenance",
            "• Update methods",
            "• Prevention review"
          ],
          winter: [
            "• Monitor systems",
            "• Maintain treatments",
            "• Check all areas",
            "• Update prevention",
            "• Review protocols"
          ]
        }
      }
    },
    'stink-bugs': {
      low: {
        immediate: "• Vacuum visible bugs (dispose bag outside)\n" +
                  "• Seal entry points around windows\n" +
                  "• Use light traps in problem areas\n" +
                  "• Remove by hand or vacuum\n" +
                  "• Install door sweeps",
        prevention: "• Inspect exterior for cracks\n" +
                   "• Seal gaps around utilities\n" +
                   "• Repair damaged screens\n" +
                   "• Install weather stripping\n" +
                   "• Check attic vents\n" +
                   "• Monitor light fixtures",
        seasonal: {
          spring: [
            "• Check winter hiding spots",
            "• Monitor exit points",
            "• Repair winter damage",
            "• Clean window frames",
            "• Check screen integrity"
          ],
          summer: [
            "• Monitor exterior walls",
            "• Check light fixtures",
            "• Inspect window seals",
            "• Watch for gathering",
            "• Check entry points"
          ],
          fall: [
            "• Seal entry points",
            "• Monitor sunny walls",
            "• Check attic spaces",
            "• Install barriers",
            "• Prepare prevention"
          ],
          winter: [
            "• Monitor indoor activity",
            "• Check warm areas",
            "• Maintain exclusion",
            "• Document clusters",
            "• Plan spring prevention"
          ]
        }
      },
      medium: {
        immediate: "• Contact pest professional\n" +
                  "• Install light traps\n" +
                  "• Begin exclusion work\n" +
                  "• Set up monitoring system\n" +
                  "• Treat entry points",
        prevention: "• Professional exclusion work\n" +
                   "• Regular inspections\n" +
                   "• Install preventive barriers\n" +
                   "• Modify exterior lighting\n" +
                   "• Implement monitoring system\n" +
                   "• Schedule maintenance checks",
        seasonal: {
          spring: [
            "• Professional inspection",
            "• Update exclusion",
            "• Monitor emergence",
            "• Check winter damage",
            "• Install prevention"
          ],
          summer: [
            "• Regular monitoring",
            "• Check treatments",
            "• Inspect barriers",
            "• Update prevention",
            "• Monitor activity"
          ],
          fall: [
            "• Pre-winter treatment",
            "• Complete exclusion",
            "• Monitor gathering",
            "• Update barriers",
            "• Prepare winter plan"
          ],
          winter: [
            "• Check indoor areas",
            "• Monitor clusters",
            "• Maintain exclusion",
            "• Update methods",
            "• Document activity"
          ]
        }
      },
      high: {
        immediate: "• Emergency pest service\n" +
                  "• Install professional barriers\n" +
                  "• Begin intensive treatment\n" +
                  "• Complete exclusion work\n" +
                  "• Set up monitoring stations",
        prevention: "• Monthly professional service\n" +
                   "• Complete exclusion system\n" +
                   "• Advanced monitoring setup\n" +
                   "• Regular treatment schedule\n" +
                   "• Professional maintenance plan\n" +
                   "• Staff/family education",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• System installation",
            "• Complete inspection",
            "• Update exclusion",
            "• Prevention setup"
          ],
          summer: [
            "• Regular treatments",
            "• System monitoring",
            "• Check barriers",
            "• Update prevention",
            "• Activity assessment"
          ],
          fall: [
            "• Intensive treatment",
            "• System evaluation",
            "• Complete exclusion",
            "• Update methods",
            "• Prevention review"
          ],
          winter: [
            "• Monitor systems",
            "• Maintain treatments",
            "• Check exclusion",
            "• Update prevention",
            "• Review protocols"
          ]
        }
      }
    },
    'boxelder-bugs': {
      low: {
        immediate: "• Vacuum visible bugs (dispose bag outside)\n" +
                  "• Seal entry points around windows\n" +
                  "• Use light traps in problem areas\n" +
                  "• Remove by hand or vacuum\n" +
                  "• Install door sweeps",
        prevention: "• Inspect exterior for cracks\n" +
                   "• Seal gaps around utilities\n" +
                   "• Repair damaged screens\n" +
                   "• Install weather stripping\n" +
                   "• Check attic vents\n" +
                   "• Monitor light fixtures",
        seasonal: {
          spring: [
            "• Check winter hiding spots",
            "• Monitor exit points",
            "• Repair winter damage",
            "• Clean window frames",
            "• Check screen integrity"
          ],
          summer: [
            "• Monitor exterior walls",
            "• Check light fixtures",
            "• Inspect window seals",
            "• Watch for gathering",
            "• Check entry points"
          ],
          fall: [
            "• Seal entry points",
            "• Monitor sunny walls",
            "• Check attic spaces",
            "• Install barriers",
            "• Prepare prevention"
          ],
          winter: [
            "• Monitor indoor activity",
            "• Check warm areas",
            "• Maintain exclusion",
            "• Document clusters",
            "• Plan spring prevention"
          ]
        }
      },
      medium: {
        immediate: "• Contact pest professional\n" +
                  "• Begin exclusion work\n" +
                  "• Treat gathering areas\n" +
                  "• Set up monitoring system\n" +
                  "• Install preventive barriers",
        prevention: "• Professional exclusion work\n" +
                   "• Regular inspections\n" +
                   "• Tree management plan\n" +
                   "• Modify exterior lighting\n" +
                   "• Implement monitoring system\n" +
                   "• Schedule maintenance checks",
        seasonal: {
          spring: [
            "• Professional inspection",
            "• Update exclusion",
            "• Monitor emergence",
            "• Check winter damage",
            "• Install prevention"
          ],
          summer: [
            "• Regular monitoring",
            "• Check treatments",
            "• Inspect barriers",
            "• Update prevention",
            "• Monitor activity"
          ],
          fall: [
            "• Pre-winter treatment",
            "• Complete exclusion",
            "• Monitor gathering",
            "• Update barriers",
            "• Prepare winter plan"
          ],
          winter: [
            "• Check indoor areas",
            "• Monitor clusters",
            "• Maintain exclusion",
            "• Update methods",
            "• Document activity"
          ]
        }
      },
      high: {
        immediate: "• Emergency pest service\n" +
                  "• Install professional barriers\n" +
                  "• Begin intensive treatment\n" +
                  "• Complete exclusion work\n" +
                  "• Set up monitoring stations",
        prevention: "• Monthly professional service\n" +
                   "• Complete exclusion system\n" +
                   "• Tree removal/treatment plan\n" +
                   "• Regular treatment schedule\n" +
                   "• Professional maintenance plan\n" +
                   "• Staff/family education",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• System installation",
            "• Complete inspection",
            "• Update exclusion",
            "• Prevention setup"
          ],
          summer: [
            "• Regular treatments",
            "• System monitoring",
            "• Check barriers",
            "• Update prevention",
            "• Activity assessment"
          ],
          fall: [
            "• Intensive treatment",
            "• System evaluation",
            "• Complete exclusion",
            "• Update methods",
            "• Prevention review"
          ],
          winter: [
            "• Monitor systems",
            "• Maintain treatments",
            "• Check exclusion",
            "• Update prevention",
            "• Review protocols"
          ]
        }
      }
    },
    'ladybugs': {
      low: {
        immediate: "• Vacuum visible beetles (dispose bag outside)\n" +
                  "• Seal entry points around windows\n" +
                  "• Use light traps in problem areas\n" +
                  "• Remove by hand or vacuum\n" +
                  "• Install door sweeps",
        prevention: "• Inspect exterior for cracks\n" +
                   "• Seal gaps around utilities\n" +
                   "• Repair damaged screens\n" +
                   "• Install weather stripping\n" +
                   "• Check attic vents\n" +
                   "• Monitor light fixtures",
        seasonal: {
          spring: [
            "• Check winter hiding spots",
            "• Monitor exit points",
            "• Repair winter damage",
            "• Clean window frames",
            "• Check screen integrity"
          ],
          summer: [
            "• Monitor exterior walls",
            "• Check light fixtures",
            "• Inspect window seals",
            "• Watch for gathering",
            "• Check entry points"
          ],
          fall: [
            "• Seal entry points",
            "• Monitor sunny walls",
            "• Check attic spaces",
            "• Install barriers",
            "• Prepare prevention"
          ],
          winter: [
            "• Monitor indoor activity",
            "• Check warm areas",
            "• Maintain exclusion",
            "• Document clusters",
            "• Plan spring prevention"
          ]
        }
      },
      medium: {
        immediate: "• Contact pest professional\n" +
                  "• Install light traps\n" +
                  "• Begin exclusion work\n" +
                  "• Set up monitoring system\n" +
                  "• Treat entry points",
        prevention: "• Professional exclusion work\n" +
                   "• Regular inspections\n" +
                   "• Install preventive barriers\n" +
                   "• Modify exterior lighting\n" +
                   "• Implement monitoring system\n" +
                   "• Schedule maintenance checks",
        seasonal: {
          spring: [
            "• Professional inspection",
            "• Update exclusion",
            "• Monitor emergence",
            "• Check winter damage",
            "• Install prevention"
          ],
          summer: [
            "• Regular monitoring",
            "• Check treatments",
            "• Inspect barriers",
            "• Update prevention",
            "• Monitor activity"
          ],
          fall: [
            "• Pre-winter treatment",
            "• Complete exclusion",
            "• Monitor gathering",
            "• Update barriers",
            "• Prepare winter plan"
          ],
          winter: [
            "• Check indoor areas",
            "• Monitor clusters",
            "• Maintain exclusion",
            "• Update methods",
            "• Document activity"
          ]
        }
      },
      high: {
        immediate: "• Emergency pest service\n" +
                  "• Install professional barriers\n" +
                  "• Begin intensive treatment\n" +
                  "• Complete exclusion work\n" +
                  "• Set up monitoring stations",
        prevention: "• Monthly professional service\n" +
                   "• Complete exclusion system\n" +
                   "• Advanced monitoring setup\n" +
                   "• Regular treatment schedule\n" +
                   "• Professional maintenance plan\n" +
                   "• Staff/family education",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• System installation",
            "• Complete inspection",
            "• Update exclusion",
            "• Prevention setup"
          ],
          summer: [
            "• Regular treatments",
            "• System monitoring",
            "• Check barriers",
            "• Update prevention",
            "• Activity assessment"
          ],
          fall: [
            "• Intensive treatment",
            "• System evaluation",
            "• Complete exclusion",
            "• Update methods",
            "• Prevention review"
          ],
          winter: [
            "• Monitor systems",
            "• Maintain treatments",
            "• Check exclusion",
            "• Update prevention",
            "• Review protocols"
          ]
        }
      }
    },
    'earwigs': {
      low: {
        immediate: "• Remove damp mulch near foundation\n" +
                  "• Clear yard debris and leaf litter\n" +
                  "• Use dehumidifiers in damp areas\n" +
                  "• Set up sticky traps\n" +
                  "• Fix leaky faucets and pipes",
        prevention: "• Maintain proper drainage\n" +
                   "• Keep gutters clean\n" +
                   "• Reduce outdoor lighting\n" +
                   "• Store firewood away from house\n" +
                   "• Remove organic debris\n" +
                   "• Control moisture levels",
        seasonal: {
          spring: [
            "• Remove winter debris",
            "• Check moisture problems",
            "• Clean garden areas",
            "• Inspect foundation",
            "• Start yard maintenance"
          ],
          summer: [
            "• Monitor mulch areas",
            "• Check garden beds",
            "• Maintain yard drainage",
            "• Inspect entry points",
            "• Control moisture"
          ],
          fall: [
            "• Remove fallen leaves",
            "• Clean garden debris",
            "• Check drainage",
            "• Reduce moisture sources",
            "• Prepare winter prevention"
          ],
          winter: [
            "• Monitor indoor areas",
            "• Check basement moisture",
            "• Maintain dehumidifiers",
            "• Inspect foundation",
            "• Document problem areas"
          ]
        }
      },
      medium: {
        immediate: "• Contact pest professional\n" +
                  "• Begin moisture remediation\n" +
                  "• Install monitoring systems\n" +
                  "• Treat perimeter areas\n" +
                  "• Begin exclusion work",
        prevention: "• Professional moisture control\n" +
                   "• Regular inspections\n" +
                   "• Install drainage solutions\n" +
                   "• Modify landscape\n" +
                   "• Implement monitoring system\n" +
                   "• Schedule maintenance checks",
        seasonal: {
          spring: [
            "• Professional inspection",
            "• Update moisture control",
            "• Check winter damage",
            "• Monitor activity",
            "• Install prevention"
          ],
          summer: [
            "• Regular monitoring",
            "• Check treatments",
            "• Inspect moisture levels",
            "• Update prevention",
            "• Monitor activity"
          ],
          fall: [
            "• Pre-winter treatment",
            "• Complete exclusion",
            "• Monitor moisture",
            "• Update barriers",
            "• Prepare winter plan"
          ],
          winter: [
            "• Check indoor areas",
            "• Monitor moisture",
            "• Maintain exclusion",
            "• Update methods",
            "• Document activity"
          ]
        }
      },
      high: {
        immediate: "• Emergency pest service\n" +
                  "• Install moisture control\n" +
                  "• Begin intensive treatment\n" +
                  "• Complete exclusion work\n" +
                  "• Set up monitoring stations",
        prevention: "• Monthly professional service\n" +
                   "• Complete moisture management\n" +
                   "• Advanced monitoring setup\n" +
                   "• Regular treatment schedule\n" +
                   "• Professional maintenance plan\n" +
                   "• Landscape modification",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• System installation",
            "• Complete inspection",
            "• Update moisture control",
            "• Prevention setup"
          ],
          summer: [
            "• Regular treatments",
            "• System monitoring",
            "• Check moisture",
            "• Update prevention",
            "• Activity assessment"
          ],
          fall: [
            "• Intensive treatment",
            "• System evaluation",
            "• Complete exclusion",
            "• Update methods",
            "• Prevention review"
          ],
          winter: [
            "• Monitor systems",
            "• Maintain treatments",
            "• Check moisture",
            "• Update prevention",
            "• Review protocols"
          ]
        }
      }
    },
    'crickets': {
      low: {
        immediate: "• Reduce outdoor lighting\n" +
                  "• Remove hiding spots near foundation\n" +
                  "• Use sticky traps in corners\n" +
                  "• Dehumidify damp areas\n" +
                  "• Seal entry points",
        prevention: "• Install yellow 'bug' lights outside\n" +
                 "• Keep grass trimmed short\n" +
                 "• Remove wood piles near house\n" +
                 "• Fix water leaks\n" +
                 "• Maintain weather stripping\n" +
                 "• Clear vegetation from foundation",
        seasonal: {
          spring: [
            "• Check winter entry points",
            "• Repair foundation cracks",
            "• Clean yard debris",
            "• Check door seals",
            "• Start yard maintenance"
          ],
          summer: [
            "• Monitor outdoor lighting",
            "• Check moisture areas",
            "• Maintain yard",
            "• Inspect entry points",
            "• Check basement areas"
          ],
          fall: [
            "• Seal entry points",
            "• Remove yard debris",
            "• Check weather stripping",
            "• Monitor activity increase",
            "• Prepare winter prevention"
          ],
          winter: [
            "• Monitor indoor areas",
            "• Check warm spots",
            "• Maintain exclusion",
            "• Check basement corners",
            "• Document problem areas"
          ]
        }
      },
      medium: {
        immediate: "• Contact pest professional\n" +
                  "• Begin exclusion work\n" +
                  "• Install monitoring systems\n" +
                  "• Treat entry points\n" +
                  "• Modify exterior lighting",
        prevention: "• Professional exclusion work\n" +
                 "• Regular inspections\n" +
                 "• Install preventive barriers\n" +
                 "• Lighting modification plan\n" +
                 "• Implement monitoring system\n" +
                 "• Schedule maintenance checks",
        seasonal: {
          spring: [
            "• Professional inspection",
            "• Update exclusion",
            "• Check winter damage",
            "• Monitor activity",
            "• Install prevention"
          ],
          summer: [
            "• Regular monitoring",
            "• Check treatments",
            "• Inspect barriers",
            "• Update prevention",
            "• Monitor activity"
          ],
          fall: [
            "• Pre-winter treatment",
            "• Complete exclusion",
            "• Monitor increase",
            "• Update barriers",
            "• Prepare winter plan"
          ],
          winter: [
            "• Check indoor areas",
            "• Monitor treatments",
            "• Maintain exclusion",
            "• Update methods",
            "• Document activity"
          ]
        }
      },
      high: {
        immediate: "• Emergency pest service\n" +
                  "• Install professional barriers\n" +
                  "• Begin intensive treatment\n" +
                  "• Complete exclusion work\n" +
                  "• Set up monitoring stations",
        prevention: "• Monthly professional service\n" +
                 "• Complete exclusion system\n" +
                 "• Advanced monitoring setup\n" +
                 "• Regular treatment schedule\n" +
                 "• Professional maintenance plan\n" +
                 "• Exterior modification plan",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• System installation",
            "• Complete inspection",
            "• Update exclusion",
            "• Prevention setup"
          ],
          summer: [
            "• Regular treatments",
            "• System monitoring",
            "• Check barriers",
            "• Update prevention",
            "• Activity assessment"
          ],
          fall: [
            "• Intensive treatment",
            "• System evaluation",
            "• Complete exclusion",
            "• Update methods",
            "• Prevention review"
          ],
          winter: [
            "• Monitor systems",
            "• Maintain treatments",
            "• Check exclusion",
            "• Update prevention",
            "• Review protocols"
          ]
        }
      }
    },
    'centipedes': {
      low: {
        immediate: "• Reduce moisture in basement/bathroom\n" +
                  "• Use dehumidifiers in damp areas\n" +
                  "• Set sticky traps in corners\n" +
                  "• Remove clutter in dark areas\n" +
                  "• Fix leaky pipes and faucets",
        prevention: "• Control indoor humidity\n" +
                   "• Seal cracks in foundation\n" +
                   "• Fix water leaks promptly\n" +
                   "• Remove other pest food sources\n" +
                   "• Keep basements well-ventilated\n" +
                   "• Reduce clutter in storage areas",
        seasonal: {
          spring: [
            "• Check winter moisture damage",
            "• Inspect foundation cracks",
            "• Clean storage areas",
            "• Check ventilation",
            "• Start moisture control"
          ],
          summer: [
            "• Monitor humidity levels",
            "• Check basement areas",
            "• Inspect dark corners",
            "• Maintain ventilation",
            "• Check entry points"
          ],
          fall: [
            "• Seal entry points",
            "• Check moisture levels",
            "• Prepare winter prevention",
            "• Clean storage areas",
            "• Monitor activity"
          ],
          winter: [
            "• Monitor indoor areas",
            "• Check warm, damp spots",
            "• Maintain dehumidifiers",
            "• Inspect basement",
            "• Document problem areas"
          ]
        }
      },
      medium: {
        immediate: "• Contact pest professional\n" +
                  "• Begin moisture remediation\n" +
                  "• Install monitoring systems\n" +
                  "• Treat problem areas\n" +
                  "• Start exclusion work",
        prevention: "• Professional moisture control\n" +
                   "• Regular inspections\n" +
                   "• Install ventilation systems\n" +
                   "• Modify storage areas\n" +
                   "• Implement monitoring system\n" +
                   "• Schedule maintenance checks",
        seasonal: {
          spring: [
            "• Professional inspection",
            "• Update moisture control",
            "• Check winter damage",
            "• Monitor activity",
            "• Install prevention"
          ],
          summer: [
            "• Regular monitoring",
            "• Check treatments",
            "• Inspect moisture levels",
            "• Update prevention",
            "• Monitor activity"
          ],
          fall: [
            "• Pre-winter treatment",
            "• Complete exclusion",
            "• Monitor moisture",
            "• Update barriers",
            "• Prepare winter plan"
          ],
          winter: [
            "• Check indoor areas",
            "• Monitor moisture",
            "• Maintain exclusion",
            "• Update methods",
            "• Document activity"
          ]
        }
      },
      high: {
        immediate: "• Emergency pest service\n" +
                  "• Install moisture control\n" +
                  "• Begin intensive treatment\n" +
                  "• Complete exclusion work\n" +
                  "• Set up monitoring stations",
        prevention: "• Monthly professional service\n" +
                   "• Complete moisture management\n" +
                   "• Advanced monitoring setup\n" +
                   "• Regular treatment schedule\n" +
                   "• Professional maintenance plan\n" +
                   "• Ventilation system upgrade",
        seasonal: {
          spring: [
            "• Full property treatment",
            "• System installation",
            "• Complete inspection",
            "• Update moisture control",
            "• Prevention setup"
          ],
          summer: [
            "• Regular treatments",
            "• System monitoring",
            "• Check moisture",
            "• Update prevention",
            "• Activity assessment"
          ],
          fall: [
            "• Intensive treatment",
            "• System evaluation",
            "• Complete exclusion",
            "• Update methods",
            "• Prevention review"
          ],
          winter: [
            " Monitor systems",
            "• Maintain treatments",
            "• Check moisture",
            "• Update prevention",
            "• Review protocols"
          ]
        }
      }
    },
    'millipedes': {
        low: {
            immediate: "• Remove mulch from foundation\n" +
                      "• Clear yard debris and leaves\n" +
                      "• Use dehumidifiers in damp areas\n" +
                      "• Set up sticky traps\n" +
                      "• Fix moisture issues",
            prevention: "• Maintain proper drainage\n" +
                       "• Keep gutters clean\n" +
                       "• Remove organic debris\n" +
                       "• Control moisture levels\n" +
                       "• Seal entry points\n" +
                       "• Improve ventilation",
            seasonal: {
                spring: [
                    "• Remove winter debris",
                    "• Check moisture problems",
                    "• Clean garden areas",
                    "• Inspect foundation",
                    "• Start yard maintenance"
                ],
                summer: [
                    "• Monitor mulch areas",
                    "• Check garden beds",
                    "• Maintain yard drainage",
                    "• Inspect entry points",
                    "• Control moisture"
                ],
                fall: [
                    "• Remove fallen leaves",
                    "• Clean garden debris",
                    "• Check drainage",
                    "• Reduce moisture sources",
                    "• Prepare winter prevention"
                ],
                winter: [
                    "• Monitor indoor areas",
                    "• Check basement moisture",
                    "• Maintain dehumidifiers",
                    "• Inspect foundation",
                    "• Document problem areas"
                ]
            }
        },
        medium: {
            immediate: "• Contact pest professional\n" +
                      "• Begin moisture remediation\n" +
                      "• Install monitoring systems\n" +
                      "• Treat perimeter areas\n" +
                      "• Begin exclusion work",
            prevention: "• Professional moisture control\n" +
                       "• Regular inspections\n" +
                       "• Install drainage solutions\n" +
                       "• Modify landscape\n" +
                       "• Implement monitoring system\n" +
                       "• Schedule maintenance checks",
            seasonal: {
                spring: [
                    "• Professional inspection",
                    "• Update moisture control",
                    "• Check winter damage",
                    "• Monitor activity",
                    "• Install prevention"
                ],
                summer: [
                    "• Regular monitoring",
                    "• Check treatments",
                    "• Inspect moisture levels",
                    "• Update prevention",
                    "• Monitor activity"
                ],
                fall: [
                    "• Pre-winter treatment",
                    "• Complete exclusion",
                    "• Monitor moisture",
                    "• Update barriers",
                    "• Prepare winter plan"
                ],
                winter: [
                    "• Check indoor areas",
                    "• Monitor moisture",
                    "• Maintain exclusion",
                    "• Update methods",
                    "• Document activity"
                ]
            }
        },
        high: {
            immediate: "• Emergency pest service\n" +
                      "• Install moisture control\n" +
                      "• Begin intensive treatment\n" +
                      "• Complete exclusion work\n" +
                      "• Set up monitoring stations",
            prevention: "• Monthly professional service\n" +
                       "• Complete moisture management\n" +
                       "• Advanced monitoring setup\n" +
                       "• Regular treatment schedule\n" +
                       "• Professional maintenance plan\n" +
                       "• Landscape modification",
            seasonal: {
                spring: [
                    "• Full property treatment",
                    "• System installation",
                    "• Complete inspection",
                    "• Update moisture control",
                    "• Prevention setup"
                ],
                summer: [
                    "• Regular treatments",
                    "• System monitoring",
                    "• Check moisture",
                    "• Update prevention",
                    "• Activity assessment"
                ],
                fall: [
                    "• Intensive treatment",
                    "• System evaluation",
                    "• Complete exclusion",
                    "• Update methods",
                    "• Prevention review"
                ],
                winter: [
                    "• Monitor systems",
                    "• Maintain treatments",
                    "• Check moisture",
                    "• Update prevention",
                    "• Review protocols"
                ]
            }
        }
    },
    'mice': {
        low: {
            immediate: "• Set snap traps along walls\n" +
                      "• Seal food in airtight containers\n" +
                      "• Clean up food debris immediately\n" +
                      "• Remove outdoor food sources\n" +
                      "• Identify and seal entry points",
            prevention: "• Regular inspection for entry points\n" +
                       "• Keep food in sealed containers\n" +
                       "• Clean up pet food overnight\n" +
                       "• Maintain yard cleanliness\n" +
                       "• Install door sweeps\n" +
                       "• Seal utility entries",
            seasonal: {
                spring: [
                    "• Check winter entry points",
                    "• Inspect exterior foundation",
                    "• Clean storage areas",
                    "• Remove yard debris",
                    "• Check outdoor storage"
                ],
                summer: [
                    "• Monitor outdoor activity",
                    "• Check garden areas",
                    "• Inspect garage/shed",
                    "• Maintain yard cleanup",
                    "• Check entry points"
                ],
                fall: [
                    "• Seal entry points",
                    "• Remove food sources",
                    "• Check weather stripping",
                    "• Clean storage areas",
                    "• Prepare winter prevention"
                ],
                winter: [
                    "• Monitor indoor activity",
                    "• Check food storage",
                    "• Maintain traps",
                    "• Inspect utility entries",
                    "• Document problem areas"
                ]
            }
        },
        medium: {
            immediate: "• Contact pest professional\n" +
                      "• Set up comprehensive trapping\n" +
                      "• Begin exclusion work\n" +
                      "• Remove all food sources\n" +
                      "• Install monitoring stations",
            prevention: "• Professional exclusion work\n" +
                       "• Regular inspections\n" +
                       "• Install barrier systems\n" +
                       "• Modify storage practices\n" +
                       "• Implement monitoring system\n" +
                       "• Schedule maintenance checks",
            seasonal: {
                spring: [
                    "• Professional inspection",
                    "• Update exclusion",
                    "• Check winter damage",
                    "• Monitor activity",
                    "• Install prevention"
                ],
                summer: [
                    "• Regular monitoring",
                    "• Check treatments",
                    "• Inspect barriers",
                    "• Update prevention",
                    "• Monitor activity"
                ],
                fall: [
                    "• Pre-winter treatment",
                    "• Complete exclusion",
                    "• Monitor entry points",
                    "• Update barriers",
                    "• Prepare winter plan"
                ],
                winter: [
                    "• Check indoor areas",
                    "• Monitor stations",
                    "• Maintain exclusion",
                    "• Update methods",
                    "• Document activity"
                ]
            }
        },
        high: {
            immediate: "• Emergency pest service\n" +
                      "• Install professional barriers\n" +
                      "• Begin intensive treatment\n" +
                      "• Complete exclusion work\n" +
                      "• Set up monitoring stations",
            prevention: "• Monthly professional service\n" +
                       "• Complete exclusion system\n" +
                       "• Advanced monitoring setup\n" +
                       "• Regular treatment schedule\n" +
                       "• Professional maintenance plan\n" +
                       "• Staff/family education",
            seasonal: {
                spring: [
                    "• Full property treatment",
                    "• System installation",
                    "• Complete inspection",
                    "• Update exclusion",
                    "• Prevention setup"
                ],
                summer: [
                    "• Regular treatments",
                    "• System monitoring",
                    "• Check barriers",
                    "• Update prevention",
                    "• Activity assessment"
                ],
                fall: [
                    "• Intensive treatment",
                    "• System evaluation",
                    "• Complete exclusion",
                    "• Update methods",
                    "• Prevention review"
                ],
                winter: [
                    "• Monitor systems",
                    "• Maintain treatments",
                    "• Check exclusion",
                    "• Update prevention",
                    "• Review protocols"
                ]
            }
        }
    },
    'rats': {
        low: {
            immediate: "• Set snap traps in active areas\n" +
                      "• Seal food in metal/glass containers\n" +
                      "• Clean up food and water sources\n" +
                      "• Remove outdoor harborage\n" +
                      "• Identify and seal entry points",
            prevention: "• Regular inspection for burrows\n" +
                       "• Keep food in sealed containers\n" +
                       "• Remove outdoor food sources\n" +
                       "• Trim vegetation from walls\n" +
                       "• Install metal door sweeps\n" +
                       "• Seal gaps larger than ½ inch",
            seasonal: {
                spring: [
                    "• Check winter entry points",
                    "• Inspect exterior foundation",
                    "• Clean yard debris",
                    "• Check outdoor storage",
                    "• Monitor burrow activity"
                ],
                summer: [
                    "• Monitor outdoor activity",
                    "• Check compost areas",
                    "• Inspect garden/sheds",
                    "• Maintain yard cleanup",
                    "• Check drainage areas"
                ],
                fall: [
                    "• Seal entry points",
                    "• Remove food sources",
                    "• Check roof access",
                    "• Clean storage areas",
                    "• Prepare winter prevention"
                ],
                winter: [
                    "• Monitor indoor activity",
                    "• Check food storage",
                    "• Maintain traps",
                    "• Inspect utility entries",
                    "• Document problem areas"
                ]
            }
        },
        medium: {
            immediate: "• Contact pest professional\n" +
                      "• Set up comprehensive trapping\n" +
                      "• Begin exclusion work\n" +
                      "• Remove all attractants\n" +
                      "• Install monitoring stations",
            prevention: "• Professional exclusion work\n" +
                       "• Regular property inspections\n" +
                       "• Install barrier systems\n" +
                       "• Modify exterior conditions\n" +
                       "• Implement monitoring system\n" +
                       "• Schedule maintenance checks",
            seasonal: {
                spring: [
                    "• Professional inspection",
                    "• Update exclusion",
                    "• Check winter damage",
                    "• Monitor burrows",
                    "• Install prevention"
                ],
                summer: [
                    "• Regular monitoring",
                    "• Check treatments",
                    "• Inspect barriers",
                    "• Update prevention",
                    "• Monitor activity"
                ],
                fall: [
                    "• Pre-winter treatment",
                    "• Complete exclusion",
                    "• Monitor entry points",
                    "• Update barriers",
                    "• Prepare winter plan"
                ],
                winter: [
                    "• Check indoor areas",
                    "• Monitor stations",
                    "• Maintain exclusion",
                    "• Update methods",
                    "• Document activity"
                ]
            }
        },
        high: {
            immediate: "• Emergency pest service\n" +
                      "• Install professional barriers\n" +
                      "• Begin intensive treatment\n" +
                      "• Complete exclusion work\n" +
                      "• Set up monitoring stations",
            prevention: "• Monthly professional service\n" +
                       "• Complete exclusion system\n" +
                       "• Advanced monitoring setup\n" +
                       "• Regular treatment schedule\n" +
                       "• Professional maintenance plan\n" +
                       "• Property modification plan",
            seasonal: {
                spring: [
                    "• Full property treatment",
                    "• System installation",
                    "• Complete inspection",
                    "• Update exclusion",
                    "• Prevention setup"
                ],
                summer: [
                    "• Regular treatments",
                    "• System monitoring",
                    "• Check barriers",
                    "• Update prevention",
                    "• Activity assessment"
                ],
                fall: [
                    "• Intensive treatment",
                    "• System evaluation",
                    "• Complete exclusion",
                    "• Update methods",
                    "• Prevention review"
                ],
                winter: [
                    "• Monitor systems",
                    "• Maintain treatments",
                    "• Check exclusion",
                    "• Update prevention",
                    "• Review protocols"
                ]
            }
        }
    },
    'spiders': {
        low: {
            immediate: "• Remove visible webs\n" +
                      "• Vacuum corners and baseboards\n" +
                      "• Reduce clutter in storage areas\n" +
                      "• Remove harborage near foundation\n" +
                      "• Check window/door seals",
            prevention: "• Regular web removal\n" +
                         "• Seal entry points\n" +
                         "• Reduce exterior lighting\n" +
                         "• Remove storage clutter\n" +
                         "• Keep vegetation trimmed\n" +
                         "• Clean dark corners regularly",
            seasonal: {
                spring: [
                    "• Check winter hiding spots",
                    "• Clean storage areas",
                    "• Remove old webs",
                    "• Inspect window frames",
                    "• Start exterior maintenance"
                ],
                summer: [
                    "• Monitor web activity",
                    "• Check dark areas",
                    "• Inspect exterior walls",
                    "• Maintain yard cleanup",
                    "• Check entry points"
                ],
                fall: [
                    "• Seal entry points",
                    "• Remove exterior webs",
                    "• Check weather stripping",
                    "• Clean storage areas",
                    "• Prepare winter prevention"
                ],
                winter: [
                    "• Monitor indoor activity",
                    "• Check warm areas",
                    "• Clean corners/baseboards",
                    "• Inspect utility entries",
                    "• Document problem areas"
                ]
            }
        },
        medium: {
            immediate: "• Contact pest professional\n" +
                      "• Begin web removal program\n" +
                      "• Start exclusion work\n" +
                      "• Remove harborage areas\n" +
                      "• Install monitoring stations",
            prevention: "• Professional web treatments\n" +
                         "• Regular inspections\n" +
                         "• Install preventive measures\n" +
                         "• Modify exterior conditions\n" +
                         "• Implement monitoring system\n" +
                         "• Schedule maintenance checks",
            seasonal: {
                spring: [
                    "• Professional inspection",
                    "• Update exclusion",
                    "• Check winter areas",
                    "• Monitor activity",
                    "• Install prevention"
                ],
                summer: [
                    "• Regular monitoring",
                    "• Check treatments",
                    "• Inspect barriers",
                    "• Update prevention",
                    "• Monitor web activity"
                ],
                fall: [
                    "• Pre-winter treatment",
                    "• Complete exclusion",
                    "• Monitor entry points",
                    "• Update barriers",
                    "• Prepare winter plan"
                ],
                winter: [
                    "• Check indoor areas",
                    "• Monitor stations",
                    "• Maintain exclusion",
                    "• Update methods",
                    "• Document activity"
                ]
            }
        },
        high: {
            immediate: "• Emergency pest service\n" +
                      "��� Install professional barriers\n" +
                      "• Begin intensive treatment\n" +
                      "• Complete exclusion work\n" +
                      "• Set up monitoring stations",
            prevention: "• Monthly professional service\n" +
                         "• Complete exclusion system\n" +
                         "• Advanced monitoring setup\n" +
                         "• Regular treatment schedule\n" +
                         "• Professional maintenance plan\n" +
                         "• Property modification plan",
            seasonal: {
                spring: [
                    "• Full property treatment",
                    "• System installation",
                    "• Complete inspection",
                    "• Update exclusion",
                    "• Prevention setup"
                ],
                summer: [
                    "• Regular treatments",
                    "• System monitoring",
                    "• Check barriers",
                    "• Update prevention",
                    "• Activity assessment"
                ],
                fall: [
                    "• Intensive treatment",
                    "• System evaluation",
                    "• Complete exclusion",
                    "• Update methods",
                    "• Prevention review"
                ],
                winter: [
                    "• Monitor systems",
                    "• Maintain treatments",
                    "• Check exclusion",
                    "• Update prevention",
                    "• Review protocols"
                ]
            }
        }
    },
    'scorpions': {
        low: {
            immediate: "• Remove harborage near foundation\n" +
                      "• Use sticky traps in corners\n" +
                      "• Seal entry points\n" +
                      "• Remove wood/rock piles\n" +
                      "• Fix moisture issues",
            prevention: "• Regular foundation inspection\n" +
                       "• Seal cracks and crevices\n" +
                       "• Keep yard debris clear\n" +
                       "• Control moisture levels\n" +
                       "• Install door sweeps\n" +
                       "• Maintain weather stripping",
            seasonal: {
                spring: [
                    "• Check winter entry points",
                    "• Inspect foundation cracks",
                    "• Clean storage areas",
                    "• Remove yard debris",
                    "• Start prevention program"
                ],
                summer: [
                    "• Monitor night activity",
                    "• Check dark areas",
                    "• Inspect exterior walls",
                    "• Maintain yard cleanup",
                    "• Check entry points"
                ],
                fall: [
                    "• Seal entry points",
                    "• Remove harborage",
                    "• Check weather stripping",
                    "• Clean storage areas",
                    "• Prepare winter prevention"
                ],
                winter: [
                    "• Monitor indoor activity",
                    "• Check warm areas",
                    "• Maintain exclusion",
                    "• Inspect utility entries",
                    "• Document problem areas"
                ]
            }
        },
        medium: {
            immediate: "• Contact pest professional\n" +
                      "• Begin exclusion work\n" +
                      "• Install monitoring systems\n" +
                      "• Remove harborage areas\n" +
                      "• Start barrier treatments",
            prevention: "• Professional exclusion work\n" +
                       "• Regular inspections\n" +
                       "• Install preventive barriers\n" +
                       "• Modify exterior conditions\n" +
                       "• Implement monitoring system\n" +
                       "• Schedule maintenance checks",
            seasonal: {
                spring: [
                    "• Professional inspection",
                    "• Update exclusion",
                    "• Check winter damage",
                    "• Monitor activity",
                    "• Install prevention"
                ],
                summer: [
                    "• Regular monitoring",
                    "• Check treatments",
                    "• Inspect barriers",
                    "• Update prevention",
                    "• Monitor activity"
                ],
                fall: [
                    "• Pre-winter treatment",
                    "• Complete exclusion",
                    "• Monitor entry points",
                    "• Update barriers",
                    "• Prepare winter plan"
                ],
                winter: [
                    "• Check indoor areas",
                    "• Monitor stations",
                    "• Maintain exclusion",
                    "• Update methods",
                    "• Document activity"
                ]
            }
        },
        high: {
            immediate: "• Emergency pest service\n" +
                      "• Install professional barriers\n" +
                      "• Begin intensive treatment\n" +
                      "• Complete exclusion work\n" +
                      "• Set up monitoring stations",
            prevention: "• Monthly professional service\n" +
                       "• Complete exclusion system\n" +
                       "• Advanced monitoring setup\n" +
                       "• Regular treatment schedule\n" +
                       "• Professional maintenance plan\n" +
                       "• Property modification plan",
            seasonal: {
                spring: [
                    "• Full property treatment",
                    "• System installation",
                    "• Complete inspection",
                    "• Update exclusion",
                    "• Prevention setup"
                ],
                summer: [
                    "• Regular treatments",
                    "• System monitoring",
                    "• Check barriers",
                    "• Update prevention",
                    "• Activity assessment"
                ],
                fall: [
                    "• Intensive treatment",
                    "• System evaluation",
                    "• Complete exclusion",
                    "• Update methods",
                    "• Prevention review"
                ],
                winter: [
                    "• Monitor systems",
                    "• Maintain treatments",
                    "• Check exclusion",
                    "• Update prevention",
                    "• Review protocols"
                ]
            }
        }
    },
    'mites': {
        low: {
            immediate: "• Vacuum thoroughly and frequently\n" +
                      "• Wash bedding in hot water\n" +
                      "• Use dehumidifiers\n" +
                      "• Clean upholstered furniture\n" +
                      "• Check pets for infestations",
            prevention: "• Regular deep cleaning\n" +
                       "• Control indoor humidity\n" +
                       "• Wash bedding weekly\n" +
                       "• Regular pet treatment\n" +
                       "• Monitor moisture levels\n" +
                       "• Keep humidity below 50%",
            seasonal: {
                spring: [
                    "• Deep clean all bedding",
                    "• Check stored items",
                    "• Monitor humidity",
                    "• Inspect pet areas",
                    "• Start prevention program"
                ],
                summer: [
                    "• Monitor humidity levels",
                    "• Check pet bedding",
                    "• Clean furniture",
                    "• Maintain cleaning schedule",
                    "• Check problem areas"
                ],
                fall: [
                    "• Pre-winter cleaning",
                    "• Check stored items",
                    "• Monitor moisture",
                    "• Clean pet areas",
                    "• Update prevention"
                ],
                winter: [
                    "• Monitor indoor areas",
                    "• Check warm spots",
                    "• Maintain humidity control",
                    "• Regular cleaning",
                    "• Document problem areas"
                ]
            }
        },
        medium: {
            immediate: "• Contact pest professional\n" +
                      "• Begin treatment program\n" +
                      "• Install humidity control\n" +
                      "• Coordinate pet treatment\n" +
                      "• Start monitoring system",
            prevention: "• Professional treatment plan\n" +
                       "• Regular inspections\n" +
                       "• Install moisture control\n" +
                       "• Modify environment\n" +
                       "• Implement monitoring system\n" +
                       "• Schedule maintenance checks",
            seasonal: {
                spring: [
                    "• Professional inspection",
                    "• Update treatments",
                    "• Check winter areas",
                    "• Monitor activity",
                    "• Install prevention"
                ],
                summer: [
                    "• Regular monitoring",
                    "• Check treatments",
                    "• Inspect problem areas",
                    "• Update prevention",
                    "• Monitor humidity"
                ],
                fall: [
                    "• Pre-winter treatment",
                    "• Complete inspection",
                    "• Monitor moisture",
                    "• Update methods",
                    "• Prepare winter plan"
                ],
                winter: [
                    "• Check indoor areas",
                    "• Monitor treatments",
                    "• Maintain humidity",
                    "• Update methods",
                    "• Document activity"
                ]
            }
        },
        high: {
            immediate: "• Emergency pest service\n" +
                      "• Install humidity control\n" +
                      "• Begin intensive treatment\n" +
                      "• Complete environment modification\n" +
                      "• Set up monitoring stations",
            prevention: "• Weekly professional service\n" +
                       "• Complete moisture management\n" +
                       "• Advanced monitoring setup\n" +
                       "• Regular treatment schedule\n" +
                       "• Professional maintenance plan\n" +
                       "• Environmental modification",
            seasonal: {
                spring: [
                    "• Full property treatment",
                    "• System installation",
                    "• Complete inspection",
                    "• Update controls",
                    "• Prevention setup"
                ],
                summer: [
                    "• Weekly treatments",
                    "• System monitoring",
                    "• Check humidity",
                    "• Update prevention",
                    "• Activity assessment"
                ],
                fall: [
                    "• Intensive treatment",
                    "• System evaluation",
                    "• Complete inspection",
                    "• Update methods",
                    "• Prevention review"
                ],
                winter: [
                    "• Monitor systems",
                    "• Maintain treatments",
                    "• Check humidity",
                    "• Update prevention",
                    "• Review protocols"
                ]
            }
        }
    }
    // We'll add more pests here
  };

  const getSolution = () => {
    if (!selectedPest || !severity) return '';
    
    const selectedSolution = solutions[selectedPest.id]?.[severity.id];
    
    if (!selectedSolution) {
      return "For this specific pest and severity level, we recommend consulting with a licensed pest control professional.";
    }

    return (
      selectedSolution.immediate + 
      selectedSolution.prevention + 
      selectedSolution.seasonal
    );
  };

  // Reset function
  const resetFlow = () => {
    setSelectedCategory(null);
    setSelectedPest(null);
    setSeverity(null);
    navigate('/');
  };

  return (
    <div className="flowchart-wrapper">
      <div className="flowchart-container">
        <AnimatePresence mode="wait">
          {/* Category Selection */}
          {!selectedCategory && (
            <motion.div 
              className="card"
              variants={cardVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <h2>What type of pest are you dealing with?</h2>
              <div className="button-grid">
                {categories.map((category) => (
                  <motion.button
                    key={category.id}
                    className="category-button"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category.name}
                  </motion.button>
                ))}
              </div>
            </motion.div>
          )}

          {/* Pest Selection */}
          {selectedCategory && !selectedPest && (
            <motion.div 
              className="card"
              variants={cardVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <h2>Which {selectedCategory.name.toLowerCase()} are you seeing?</h2>
              <div className="button-grid">
                {pests
                  .filter(pest => pest.category === selectedCategory.id)
                  .map((pest) => (
                    <motion.button
                      key={pest.id}
                      className="category-button"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={() => setSelectedPest(pest)}
                    >
                      {pest.name}
                    </motion.button>
                  ))}
              </div>
              <motion.button
                className="back-button"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setSelectedCategory(null)}
              >
                Back
              </motion.button>
            </motion.div>
          )}

          {/* Severity Selection */}
          {selectedPest && !severity && (
            <motion.div 
              className="card"
              variants={cardVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <h2>How severe is the problem?</h2>
              <div className="button-grid">
                {severityLevels.map(level => (
                  <motion.button
                    key={level.id}
                    className="category-button"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => setSeverity(level)}
                  >
                    {level.name}
                  </motion.button>
                ))}
              </div>
              <motion.button
                className="back-button"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setSelectedPest(null)}
              >
                Back
              </motion.button>
            </motion.div>
          )}

          {/* Solution Display */}
          {selectedPest && severity && (
            <motion.div 
              className="card solution-card"
              variants={cardVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <div className="solution-header">
                <h2>Recommended Solution for {selectedPest.name}</h2>
                <div className="severity-badge" data-severity={severity.id}>
                  Severity: {severity.name}
                </div>
              </div>

              <div className="solution-content">
                {/* Immediate Actions Section */}
                <div className="solution-section">
                  <h3>🎯 Immediate Actions</h3>
                  <div className="content-box">
                    {solutions[selectedPest.id][severity.id].immediate.split('\n').map((line, index) => (
                      line.startsWith('•') ? (
                        <div key={index} className="action-item">
                          <span className="bullet">•</span>
                          <span>{line.substring(1).trim()}</span>
                        </div>
                      ) : <p key={index}>{line}</p>
                    ))}
                  </div>
                </div>

                {/* Prevention Section */}
                <div className="solution-section">
                  <h3>🛡️ Prevention Tips</h3>
                  <div className="content-box">
                    {solutions[selectedPest.id][severity.id].prevention.split('\n').map((line, index) => (
                      line.startsWith('•') ? (
                        <div key={index} className="action-item">
                          <span className="bullet">•</span>
                          <span>{line.substring(1).trim()}</span>
                        </div>
                      ) : <p key={index}>{line}</p>
                    ))}
                  </div>
                </div>

                {/* Seasonal Section */}
                <div className="solution-section">
                  <h3>🗓️ Seasonal Prevention</h3>
                  <div className="seasonal-grid">
                    {Object.entries(solutions[selectedPest.id][severity.id].seasonal).map(([season, tips]) => (
                      <div key={season} className="season-card">
                        <h4>{season.charAt(0).toUpperCase() + season.slice(1)}</h4>
                        <div className="season-content">
                          {tips.map((tip, index) => (
                            <div key={index} className="action-item">
                              <span className="bullet">•</span>
                              <span>{tip.substring(1).trim()}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="solution-footer">
                <motion.button
                  className="primary-button"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={resetFlow}
                >
                  Start Over
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default PestFinder; 